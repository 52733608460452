import { Scene } from "phaser";
import axios from "axios";
import store from "@/store";
import i18n from "@/plugins/i18n";

export default class WizardScene extends Scene {
  constructor() {
    super({ key: "WizardScene" });
  }
  init(data) {
    this.avatar = data.avatar;
    this.ladder = data.ladder;
    this.blueStar = data.blueStar;
    this.game = data.game;
    this.end = data.end;
    this.wizard = data.wizard;
    this.questions = data.questions;
    this.timestampReset = data.timestampReset;
    this.colors = data.colors,
    this.env = data.env;
    this.schoolYear = data.schoolYear,
    this.classroomMonth = data.classroomMonth,
    this.isHasSpecialItem = data.isHasSpecialItem
  }
  avatarObject = [];
  selectedItem = {};
  avatarX = 240;
  isClickedSkip = false;
  isSelectedItem = false;
  items = [
    {
      key: "goldcoin",
      price: 5,
      quantity: 10,
    },
    {
      key: "CHEST0020",
      price: 15,
      quantity: 1,
    },
  ];
  preload() {}

  create() {
    //sounds
    this.sfxClickSelect = this.sound.add("sfxClickSelect");
    this.simpleClickSound = this.sound.add("simpleClick");
    // const centerX = this.avatarX;
    // const centerY = 420;
    this._backgroundImage = this.add
      .image(0, 0, "imgBackground")
      .setDisplaySize(480, 854)
      .setOrigin(0);
    // Create a black background
    // this.add.rectangle(0, 0, 480, 854, 0xf5cba7).setOrigin(0);

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.6)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this);
    this.blustartBoard = this.add
      .image(420, 95, "imgScorePanel")
      .setDisplaySize(100, 50)
      .setDepth(2);

    this._bluestar = this.add
      .image(430, 82, "C000040")
      .setDisplaySize(30, 27)
      .setOrigin(0)
      .setDepth(2);

    this.starText = this.add
      .text(390, 93, "" + this.blueStar, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 30,
        color: "#000000",
      })
      .setOrigin(0, 0.5)
      .setFontStyle("bold")
      .setDepth(2);
      this.modelbg = this.add
      .rectangle(240, 377, 408, 100, 0xffffff)
      .setDepth(9)
      .setAlpha(0);

    this._hamochi_wizard = this.add
      .image(240 - 150, 100, "hamochiWizardMagicShop")
      .setDisplaySize(300, 229)
      .setOrigin(0)
      .setDepth(2);

    this._panel_shop = this.add
      .image(40, 350, "panelShop")
      .setDisplaySize(400, 450)
      .setOrigin(0);

    this.pickText = this.add.text(0, 370,i18n.t("miniGames.towerClimbing.pickOne"), {
      fontFamily: "Sassoon, sans-serif",
      fontSize: 30,
      color: "#000",
      align: "center",
    });
    let pickText = this.pickText.width;
    this.pickText.setX(240 - pickText / 2);

    for (var i = 0; i < 2; i++) {
      this["_item_frame_" + i] = this.add
        .image(105 + 150 * i, 440, "itemFrame")
        .setScale(0.6)
        .setOrigin(0)
        .setDepth(2)
        .setInteractive({ useHandCursor: true })
        .on("pointerdown", this.selectItem.bind(this, i), this);

      this["_item_" + i] = this.add
        .image(125 + 150 * i, 450, this.items[i].key + "")
        .setDisplaySize(80, 80)
        .setOrigin(0)
        .setDepth(2)
        .setInteractive({ useHandCursor: true })
        .on("pointerdown", this.selectItem.bind(this, i), this);

      this["_item_qty_text" + i] = this.add
        .text(
          148 + 150 * i,
          527, "+" + this.items[i].quantity,
          {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 20,
            color: "#ffffff",
            align: "center",
          }
        )
        .setDepth(3);

      this["_item_price_text" + i] = this.add
        .text(
          this.items[i].price < 10 ? 140 + 150 * i : 135 + 150 * i,
          558,"" + this.items[i].price,
          {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 30,
            color: "#000",
            align: "center",
          }
        )
        .setDepth(3);

      this["_bluestar_" + i] = this.add
        .image(this.items[i].price < 10 ? 165 + 150 * i : 175 + 150 * i, 560, "C000040")
        .setDisplaySize(30, 27)
        .setOrigin(0)
        .setDepth(2)
        .setInteractive({ useHandCursor: true })
        .on("pointerdown", this.selectItem.bind(this, i), this);
    }

    this._exchangeBtn = this.add
      .image(240, 670, "optionButton")
      .setScale(0.35)
      .setTint("0x808080");

    this._skipBtn = this.add
      .image(240, 740, "optionButton")
      .setScale(0.35)
      .setTint("0x00FF00")
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.skip, this);
    this._exchangeTxt = this.add.text(0, 660, i18n.t("action.trade"), {
      fontFamily: "Sassoon, sans-serif",
      fontSize: 20,
      color: "#000",
      align: "center",
    });
    let _exchangeTxt = this._exchangeTxt.width;
    this._exchangeTxt.setX(240 - _exchangeTxt / 2);
    this._skipTxt = this.add.text(220, 730, i18n.t("action.skip"), {
      fontFamily: "Sassoon, sans-serif",
      fontSize: 20,
      color: "#000",
      align: "center",
    });

    // Create a white circle at the center
    // const circle = this.add.circle(240, 427, 500, 0xffffff);

    // Set origin to the center for proper scaling
    //  circle.setOrigin(0.5);
    //* Player Avatar *//
    // for (let key of Object.keys(this.avatar)) {
    //   if (this.avatar[key]) {
    //     if (key == "body") {
    //       this.avatarObject.push(
    //         this.physics.add
    //           .sprite(centerX, centerY, key)
    //           .setScale(0.25)
    //           .setCollideWorldBounds(true)
    //           .setDepth(1)
    //       );
    //       this.avatarObject.push(
    //         this.physics.add
    //           .sprite(centerX, centerY, key)
    //           .setTint("0x" + this.avatar.color)
    //           .setScale(0.25)
    //           .setAlpha(0.3)
    //           .setCollideWorldBounds(true)
    //           .setDepth(1)
    //       );
    //     } else if (key != "color") {
    //       this.avatarObject.push(
    //         this.physics.add
    //           .sprite(centerX, centerY, key)
    //           .setScale(0.25)
    //           .setCollideWorldBounds(true)
    //           .setDepth(1)
    //       );
    //     }
    //   }
    // }
    // this.tweens.add({
    //   targets: this.avatarObject,
    //   alpha: 0, // Set alpha to 0 (completely transparent)
    //   duration: 700, // Duration of the tween in milliseconds
    //   onComplete: () => {
    //     this.avatarObject.visible = false; // Once completely transparent, hide the object
    //   },
    // });
    // Create a tween to reduce the circle's size to 0
    // this.tweens.add({
    //   targets: circle,
    //   scaleX: 0,
    //   scaleY: 0,
    //   alpha: 0,
    //   duration: 700, // Duration of the tween in milliseconds
    //   onComplete: () => {
    //     circle.destroy();
    //     this._hamochi_wizard = this.add
    //       .image(240-62.5, 100, "hamochiWizard")
    //       .setDisplaySize(125, 150)
    //       .setOrigin(0)
    //       .setDepth(2);

    //       this._item_frame = this.add
    //       .image(240-62.5, 300, "itemFrame")
    //       .setDisplaySize(200, 200)
    //       .setOrigin(0)
    //       .setDepth(2);

    //     this._continueBtn = this.add
    //       .image(240, 550, "optionButton")
    //       .setScale(0.35);
    //       this._skipBtn = this.add
    //       .image(240, 620, "optionButton")
    //       .setScale(0.35);
    //     this._continueTxt = this.add
    //       .text(190, 540, "EXCHANGE", {
    //         fontFamily: "Sassoon, sans-serif",
    //         fontSize: 20,
    //         color: "#000",
    //         align: "center",
    //       })
    //       .setInteractive({ useHandCursor: true })
    //       // .on("pointerdown", this.play, this);

    //       this._skipTxt = this.add
    //       .text(210, 610, "SKIP", {
    //         fontFamily: "Sassoon, sans-serif",
    //         fontSize: 20,
    //         color: "#000",
    //         align: "center",
    //       })
    //       .setInteractive({ useHandCursor: true })
    //   },
    // });
  }
  selectItem(i) {
    this.sfxClickSelect.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxClickSelect.play();
    if (this._item_frame_border) {
      this._item_frame_border.setVisible(false);
    }
 if(!this.isSelectedItem){
  this._exchangeBtn
  .clearTint()
  .setInteractive({ useHandCursor: true })
  .on("pointerdown", this.exchange, this);
  this.isSelectedItem = true
 }
    
    this._item_frame_border = this.add
      .image(105 + 150 * i, 440, "itemFrameBorder")
      .setScale(0.6)
      .setOrigin(0)
      .setDepth(2);

    this.selectedItem = this.items[i];
  }
  spendFoot() {
 
    let token = store.state.auth.data.Session.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(
        `${this.env.VUE_APP_URL_API_GAME}/api/v1/en/game/footprint/spend`,
        { quantity: 1 },
        { headers }
      )
      .then(() => {
        this.moveToNext();
      })
      .catch((error) => {
        // Handle any errors that occur during the API call
        console.error("Error:", error);
      });
  }

  exchange() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    if (this.selectedItem.price > this.blueStar) {
      this.openApiError()
    //  this.showError("Not enough blue stars");
      // this.avatarMask = this.add
      //   .image(240 - 62.5, 220, "maskGrimace")
      //   .setDisplaySize(125, 150)
      //   .setOrigin(0)
      //   .setDepth(2);
      // this.blustartBoard.setTint(0xFF0000)
      // this.time.delayedCall(1000, () => {
      //  // this.avatarMask.destroy();
      //   this.blustartBoard.clearTint()
      // })
    } else {
      this._exchangeBtn
      .off("pointerdown", this.exchange, this)
      .removeInteractive();
      let token = store.state.auth.data.Session.token;
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      axios
        .post(
          `${this.env.VUE_APP_URL_API_GAME}/api/v1/en/game/item/exchange`,
          {
            currencyKey: "C000040",
            price: this.selectedItem.price,
            itemKey: this.selectedItem.key,
            quantity: this.selectedItem.quantity,
          },
          { headers }
        )
        .then((response) => {
          // Process the data returned from the API
          console.log(response.data);
          this.blueStar = response.data.Currency.quantity;
          this.spendFoot();
        })
        .catch((error) => {
          // Handle any errors that occur during the API call
          console.error("Error:", error);
        });
    }
  }
  skip() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.isClickedSkip = true;
    this.spendFoot()
  }
  moveToNext() {
    let token = store.state.auth.data.Session.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(
        `${this.env.VUE_APP_URL_API_GAME}/api/v2/en/game/dailyGame/move`,
        { schoolYear: this.schoolYear, classroomMonth: this.classroomMonth },
        { headers }
      )
      .then(() => {
        // Process the data returned from the API
        if(store.state.auth.data.Player.userType != "adult"){
          this.submitLog()
        }
        this.time.delayedCall(700, () => {
          this.game.currentTile =
            this.game.currentTile == 5 ? 1 : this.game.currentTile + 1;
          this.game.currentFloor =
            this.game.currentTile == 1
              ? this.game.currentFloor + 1
              : this.game.currentFloor;
if(!this.isClickedSkip){
  this.scene.pause();
  this.scene.launch("CollectItem", {
    type:"wizardShop",
    sceneName: "LandingScene",
    itemCount: "+" + this.selectedItem.quantity,
    itemKey: "" + this.selectedItem.key,
    avatar: this.avatar,
    ladder: this.ladder,
    blueStar: this.blueStar,
    game: this.game,
    end: this.end,
    wizard: this.wizard,
    questions: this.questions,
    colors: this.colors,
    env: this.env,
    timestampReset: this.timestampReset,
    schoolYear: this.schoolYear,
    classroomMonth: this.classroomMonth,
    isHasSpecialItem:this.isHasSpecialItem,
  });
}else{
  this.scene.start("LandingScene", {
    avatar: this.avatar,
    ladder: this.ladder,
    blueStar: this.blueStar,
    game: this.game,
    end: this.end,
    wizard: this.wizard,
    questions: this.questions,
    colors: this.colors,
    env: this.env,
    timestampReset:this.timestampReset,
    schoolYear: this.schoolYear,
    classroomMonth: this.classroomMonth,
    isHasSpecialItem:this.isHasSpecialItem,
  });
}
          
        });
      })
      .catch((error) => {
        // Handle any errors that occur during the API call
        console.error("Error:", error);
      });
  }
  submitLog() {
    let token = store.state.auth.data.Session.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(
        `${this.env.VUE_APP_URL_API_LOG}/api/v1/en/dailyGameProgress/add`,
        {
          institutionId: store.state.auth.data.Institution?store.state.auth.data.Institution.id : null,
          classroomId: store.state.auth.data.Classroom?store.state.auth.data.Classroom.id : null,
          studentId: store.state.auth.data.Classroom? store.state.auth.data.User.id : null,
          userId: store.state.auth.data.Classroom?null:store.state.auth.data.User.id,
          schoolYear: this.schoolYear,
          classroomMonth: this.classroomMonth,
          mochiId: store.state.auth.data.Player.mochiId,
          currentFloor: this.game.currentFloor,
          currentTile: this.game.currentTile,
        },
        { headers }
      )
      .catch((error) => {
        // Handle any errors that occur during the API call
        console.error("Error:", error);
      });
  }
  showError(mgs) {
    const words = mgs.split(" ");
    const lines = [];

    while (words.length > 0) {
      lines.push(words.splice(0, 3).join(" "));
    }

    const centerX = this.cameras.main.centerX;
    const centerY = this.cameras.main.centerY - 50;
    this.tweens.add({
      targets: this.modelbg,
      alpha: 0.5,
      duration: 200,
      ease: "Sine.easeInOut",
    });
    lines.map((line, index) => {
      const text = this.add
        .text(centerX, centerY + (index - (lines.length - 1) / 2) * 30, line, {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 20,
          fill: "#ffffff",
          stroke: "#D50000",
          strokeThickness: 2,
        })
        .setDepth(10);

      // Center the text
      text.setOrigin(0.5, 0.5);

      this.tweens.add({
        targets: text,
        scaleX: 1.5,
        scaleY: 1.5,
        duration: 200,
        ease: "Sine.easeInOut",
        onComplete: () => {
          this.tweens.add({
            targets: this.modelbg,
            alpha: 0,
            duration: 200,
            delay: 1000,
            ease: "Sine.easeInOut",
          });
          this.tweens.add({
            targets: text,
            scaleX: 0,
            scaleY: 0,
            duration: 200,
            ease: "Sine.easeInOut",
            delay: 1000,
          });
        },
      });
      return text;
    });
  }
  openApiError() {
    this.scene.pause();
    this.scene.launch("ApiError", {
      sceneName: "WizardScene",
      mgs: i18n.t("miniGames.towerClimbing.blueStarError"),
    });
  }
  clickSetting() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "WizardScene",
    });
  }
  update() {}
}
