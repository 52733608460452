import { Scene } from "phaser";
import store from "@/store";
import router from "@/router";
import i18n from "@/plugins/i18n";
// import _ from "lodash";
// import Phaser from "phaser";

export default class PlayScene extends Scene {
  constructor() {
    super({ key: "PlayScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.name = data.name;
    this.round = data.round;
    this.qCount = data.qCount;
    this.questions = data.questions;
    this.score = data.score ? data.score : 0;
    this.friend = data.friend;
    this.forceFriend = data.forceFriend;
    this.countCorrect = data.countCorrect;
    this.isCorrectAnswer = data.isCorrectAnswer;
    this.difficulty = data.difficulty;
    this.noOfRounds = data.noOfRounds;
    this.noOfCuts = data.noOfCuts;
    this.skyPosition = data.skyPosition ? data.skyPosition : 0;
    this.bossRound = data.bossRound;
    this.bossImage = data.bossImage;
    this.currentCampTileX = data.currentCampTileX;
    this.nextCampTileX = data.nextCampTileX;
    this.totalBones = data.totalBones;
    this.collectedBones = data.collectedBones;
    this.collectedSkull = data.collectedSkull;
    this.lampTime = data.lampTime;
  }

  playerAvatarObject = [];

  create() {
    this.lampTimeX = this.lampTime;

    this.isLampPower = true;
    this.startPlay = false;
    this.isStartTimer = false;
    // this.registerSounds();
    this.registerAnims();

    //* setup the Background  *//

    this.topBoardBack = this.add.image(0, 0, "imgTopBack").setOrigin(0);
    this.topBoard = this.add.image(0, 0, "imgTop").setOrigin(0).setDepth(7);
    this.boneBoard = this.add
      .image(200, 60, "imgBoneBrd")
      .setOrigin(0)
      .setDepth(7);

    this.BoneText = this.add
      .text(300, 100, this.collectedBones + " / " + this.totalBones, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 30,
        color: "#fff",
      })
      .setOrigin(0, 0.5)
      .setDepth(7);

    this.boneIV = this.add
      .image(230, 75, "imgBone4")
      .setScale(0.22)
      .setOrigin(0)
      .setDepth(7);
    this.boneI = this.add
      .image(218, 92, "imgBone1")
      .setScale(0.17)
      .setOrigin(0)
      .setDepth(7);

    this.settingBtn = this.add
      .image(440, 40, "imgSettingBtn")
      .setScale(0.5)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.clickSetting, this)
      .setDepth(7);

    // this.bulb = this.add
    //   .image(440, 150, "imgBulb")
    //   .setScale(0.3)
    //   .setInteractive({ useHandCursor: true })
    //   .on(
    //     "pointerdown",
    //     () => {
    //       if (this.dark.alpha == 1) {
    //         this.dark.setAlpha(0);
    //       } else {
    //         this.dark.setAlpha(1);
    //       }
    //     },
    //     this
    //   )
    //   .setDepth(9);

    this.scoreBoard = this.add
      .image(95, 288 + 40, "imgScorePanel")
      .setScale(0.38, 0.32)
      .setDepth(7);

    this.roundBoard = this.add
      .image(73, 288 + 80, "imgScorePanel")
      .setScale(0.25)
      .setDepth(7);

    this.scoreText = this.add
      .text(
        35,
        288 + 40,
        i18n.t("miniGames.general.score") + " : " + this.score + "",
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 22,
          color: "#492a12",
        }
      )
      .setOrigin(0, 0.5)
      .setDepth(7);

    this.roundText = this.add
      .text(
        35,
        288 + 82,
        i18n.t("miniGames.general.round") + " " + this.round,
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 15,
          color: "#492a12",
        }
      )
      .setOrigin(0, 0.5)
      .setDepth(7);

    //* tiles setup later put it to function *//

    this.tiles = [];
    // this.nextCampTileX = Math.floor(Math.random() * 5);
    this.nextCampTileY = 6;
    // this.currentCampTileX = Math.floor(Math.random() * 5);
    this.currentCampTileY = 0;
    this.nextNextCampTileX = Math.floor(Math.random() * 5);
    this.nextNextCampTileY = 12;
    this.currentTileX = this.currentCampTileX;
    this.currentTileY = 0;

    this.addItems();

    this.tempBones = ["sptBone1", "sptBone2", "sptBone3"];

    for (let i = -4; i < 13; i++) {
      this.tiles[i] = [];
      for (let j = 0; j < 5; j++) {
        if (
          (i == this.nextCampTileY && j == this.nextCampTileX) ||
          (i == this.currentCampTileY && j == this.currentCampTileX) ||
          (i == this.nextNextCampTileY && j == this.nextNextCampTileX)
        ) {
          if (
            i == this.nextCampTileY &&
            j == this.nextCampTileX &&
            this.round == this.noOfRounds
          ) {
            this.tiles[i][j] = this.add
              .sprite(
                j * 96,
                this.round == 1 ? 182 + i * 96 : 384 + 182 + i * 96,
                "sptCampEnd"
              )
              .setOrigin(0)
              .setDepth(2);
          } else {
            this.tiles[i][j] = this.add
              .sprite(
                j * 96,
                this.round == 1 ? 182 + i * 96 : 384 + 182 + i * 96,
                "sptCamp"
              )
              .setOrigin(0)
              .setDepth(2);
          }

          this.tiles[i][j].state = 1;
          this.tiles[i][j].isCamp = true;
          if (i == this.currentCampTileY && j == this.currentCampTileX) {
            this.tiles[i][j].isDone = true;
            this.tiles[i][j].isCamp = false;
          }
        } else {
          this.tiles[i][j] = this.add
            .sprite(
              j * 96,
              this.round == 1 ? 182 + i * 96 : 384 + 182 + i * 96,
              "sptTile"
            )
            .setOrigin(0);

          if (this.items[i * 5 + j] == "GEM_I") {
            this.tiles[i][j].item = this.add
              .sprite(
                j * 96,
                this.round == 1 ? 182 + i * 96 : 384 + 182 + i * 96,
                "sptGem1"
              )
              .setOrigin(0);
          }
          if (this.items[i * 5 + j] == "GEM_II") {
            this.tiles[i][j].item = this.add
              .sprite(
                j * 96,
                this.round == 1 ? 182 + i * 96 : 384 + 182 + i * 96,
                "sptGem2"
              )
              .setOrigin(0);
          }
          if (this.items[i * 5 + j] == "ENERGY") {
            this.tiles[i][j].item = this.add
              .sprite(
                j * 96,
                this.round == 1 ? 182 + i * 96 : 384 + 182 + i * 96,
                "sptEnergy"
              )
              .setOrigin(0);
          }
          if (this.items[i * 5 + j] == "BONE_I") {
            this.tiles[i][j].item = this.add
              .sprite(
                j * 96,
                this.round == 1 ? 182 + i * 96 : 384 + 182 + i * 96,
                this.tempBones[
                  Math.floor(Math.random() * this.tempBones.length)
                ]
              )
              .setOrigin(0);

            this.tiles[i][j].item.blink = this.add
              .image(
                48 + j * 96,
                this.round == 1 ? 230 + i * 96 : 384 + 230 + i * 96,
                "imgBlink"
              )
              .setOrigin(0.5)
              .setScale(0)
              .setDepth(2)
              .setAlpha(0.8);

            this.tweens.add({
              targets: this.tiles[i][j].item.blink,
              scaleY: 0.2,
              scaleX: 0.2,
              delay: 0,
              duration: 200,
              ease: "Sine.easeInOut",
              yoyo: true,
              onComplete: () => {
                this.tweens.add({
                  targets: this.tiles[i][j].item.blink,
                  scaleY: 0.2,
                  scaleX: 0.2,
                  delay: 5000,
                  repeatDelay: 5000,
                  duration: 200,
                  ease: "Sine.easeInOut",
                  repeat: -1,
                  yoyo: true,
                });
              },
            });
          }
          if (this.items[i * 5 + j] == "BONE_II") {
            if (this.round == this.noOfRounds && this.isCorrectAnswer) {
              this.tiles[i][j].item = this.add
                .sprite(
                  j * 96,
                  this.round == 1 ? 182 + i * 96 : 384 + 182 + i * 96,
                  "sptBone4"
                )
                .setOrigin(0);
              this.tiles[i][j].item.isSkull = true;
            } else {
              this.tiles[i][j].item = this.add
                .sprite(
                  j * 96,
                  this.round == 1 ? 182 + i * 96 : 384 + 182 + i * 96,
                  this.tempBones[
                    Math.floor(Math.random() * this.tempBones.length)
                  ]
                )
                .setOrigin(0);
            }

            this.tiles[i][j].item.blink = this.add
              .image(
                48 + j * 96,
                this.round == 1 ? 230 + i * 96 : 384 + 230 + i * 96,
                "imgBlink"
              )
              .setOrigin(0.5)
              .setScale(0)
              .setDepth(2)
              .setAlpha(0.8);

            this.tweens.add({
              targets: this.tiles[i][j].item.blink,
              scaleY: 0.2,
              scaleX: 0.2,
              delay: 500,
              duration: 200,
              ease: "Sine.easeInOut",
              yoyo: true,
              onComplete: () => {
                if (!this.startPlay) {
                  this.startPlay = true;
                }
                this.tweens.add({
                  targets: this.tiles[i][j].item.blink,
                  scaleY: 0.2,
                  scaleX: 0.2,
                  delay: 5500,
                  repeatDelay: 5000,
                  duration: 200,
                  ease: "Sine.easeInOut",
                  repeat: -1,
                  yoyo: true,
                });
              },
            });
          }
          if (this.items[i * 5 + j] == "BOMB") {
            this.tiles[i][j].item = this.add
              .sprite(
                j * 96,
                this.round == 1 ? 182 + i * 96 : 384 + 182 + i * 96,
                Math.floor(Math.random() * 2) > 0 ? "sptBomb1" : "sptBomb2"
              )
              .setOrigin(0);
          }
          if (this.items[i * 5 + j] == "ROCK") {
            this.tiles[i][j].item = this.add
              .sprite(
                j * 96,
                this.round == 1 ? 182 + i * 96 : 384 + 182 + i * 96,
                "sptRock"
              )
              .setOrigin(0);
          }
          if (i > 6) {
            this.tiles[i][j].item = this.add
              .sprite(
                j * 96,
                this.round == 1 ? 182 + i * 96 : 384 + 182 + i * 96,
                Math.floor(Math.random() * 2) > 0 ? "sptBomb1" : "sptBomb2"
              )
              .setOrigin(0);
          }
        }
        if (i < 0) {
          this.tiles[i][j].state = 1;
          this.tiles[i][j].setFrame(2);
        }

        this.tiles[i][j].itemType = this.items[i * 5 + j];
        if (i > 6) {
          this.tiles[i][j].itemType = "BOMB";
        }
      }
    }

    this.tiles[this.currentTileY][this.currentTileX].setFrame(1);
    this.tiles[this.currentTileY][this.currentTileX].state = 1;

    this.playerPositionX = 48 + 96 * this.currentTileX;
    this.playerPositionY =
      this.round == 1
        ? 230 + 96 * this.currentTileY
        : 384 + 230 + 96 * this.currentTileY;
    this.playerOpacity = 1;

    // this.timerBox = this.add
    //   .graphics()
    //   .fillStyle(0x875f41, 1)
    //   .fillRect(this.playerPositionX - 42, this.playerPositionY - 42, 84, 12)
    //   .setDepth(8);
    // this.timerBar = this.add
    //   .graphics()
    //   .fillStyle(0xfff000, 1)
    //   .fillRect(this.playerPositionX - 40, this.playerPositionY - 40, 80, 8)
    //   .setDepth(8);

    // this.timerBarRe = this.add.graphics().fillStyle(0x875f41, 1).setDepth(9);

    this.dark = this.add
      .sprite(this.playerPositionX, this.playerPositionY, "sptDarkA")
      .setOrigin(0.5)
      .setScale(4)
      .setFrame(0);

    this.lapm = this.add
      .image(this.playerPositionX, this.playerPositionY - 5, "imgLamp")
      .setOrigin(0.5)
      .setScale(0.2)
      .setDepth(9);

    this.axe = this.add
      .image(this.playerPositionX - 5, this.playerPositionY, "imgAxe")
      .setOrigin(0.5)
      .setScale(0.18)
      .setDepth(6);

    this.leftBtn = this.add
      .image(this.playerPositionX + 48, this.playerPositionY, "imgArrow")
      .setOrigin(0, 0.5)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.digLeft, this);

    this.rightBtn = this.add
      .image(this.playerPositionX - 48, this.playerPositionY, "imgArrow")
      .setOrigin(0, 0.5)
      .setAngle(180)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.digRight, this);

    this.upBtn = this.add
      .image(this.playerPositionX, this.playerPositionY - 48, "imgArrow")
      .setOrigin(0, 0.5)
      .setAngle(-90)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.digUp, this);

    this.downBtn = this.add
      .image(this.playerPositionX, this.playerPositionY + 48, "imgArrow")
      .setOrigin(0, 0.5)
      .setAngle(90)
      .setDepth(2)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.digDown, this);

    //* Player Avatar *//
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(this.playerPositionX, this.playerPositionY, key)
              .setScale(0.18)
              // .setCollideWorldBounds(true)
              .setDepth(6)
              .setAlpha(this.playerOpacity)
          );
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(this.playerPositionX, this.playerPositionY, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.18)
              .setAlpha(0.3)
              // .setCollideWorldBounds(true)
              .setDepth(6)
          );
        } else if (key != "color" && key != "right" && key != "left") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(this.playerPositionX, this.playerPositionY, key)
              .setScale(0.18)
              // .setCollideWorldBounds(true)
              .setDepth(6)
              .setAlpha(this.playerOpacity)
          );
        }
      }
    }

    this.battry = this.add
      .sprite(this.playerPositionX, this.playerPositionY - 10, "sptBattry")
      .setDepth(8)
      .setFlipX(this.axe.flipX ? true : false)
      .setFrame(0);

    this.ideaBtn = this.add
      .sprite(
        this.playerPositionX,
        this.playerPositionY - 80,
        this.round == this.noOfRounds ? "sptIdea2" : "sptIdea"
      )
      .setOrigin(0.5)
      .setDepth(10)
      .setScale(0.8)
      .setAlpha(0)
      .setInteractive({ useHandCursor: true })
      .on("pointerdown", this.goQuestion, this);

    this.ideaBtn.play(this.round == this.noOfRounds ? "animIdea2" : "animIdea");

    this.playerTween = this.tweens.add({
      targets: this.playerAvatarObject,
      scaleY: 0.19,
      scaleX: 0.175,
      duration: 2000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    this.playerTweenz = this.tweens.add({
      targets: [this.lapm, this.axe],
      scaleY: 0.19,
      scaleX: 0.19,
      duration: 2000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    if (this.round != 1) {
      this.cameras.main.pan(240, this.playerPositionY - 90, 10, "Linear", true);
      if (!this.isCorrectAnswer) {
        this.time.delayedCall(500, () => {
          this.battry.setFrame(1);
          this.dark.setScale(3);
        });
      }
    }
  }

  powerOff() {
    console.log("power up!");
  }

  startTimer(time) {
    this.timedEvent = this.time.delayedCall(time, () => {
      this.add
        .text(240, this.cameras.main.scrollY + 500, "Game Over", {
          color: "#fff",
          fontSize: "50px",
          fontFamily: "Sassoon, sans-serif",
          stroke: "#000000",
          strokeThickness: 5,
        })
        .setDepth(8)
        .setShadow(3, 3, "#000", 0, true, true)
        .setOrigin(0.5);
      this.time.delayedCall(3000, () => {
        router.go();
      });
      this.isLampPower = false;
      this.lapm.setAlpha(0);
      this.dark.setFrame(1);
    });
    if (!this.isCorrectAnswer && this.round != 1) {
      let newTime = Math.floor(this.timedEvent.getOverallRemaining()) - 5000;
      this.timedEvent.elapsed = this.lampTimeX - newTime;
    }
  }

  update() {
    if (
      this.timedEvent &&
      !this.tiles[this.nextCampTileY][this.nextCampTileX].isDone
    ) {
      if (this.startPlay) {
        if (this.lampTimeX - this.timedEvent.elapsed < 5000) {
          if (this.isLampPower) {
            this.dark.setScale(1.4);
            this.battry.setFrame(3);
          } else {
            this.dark.setFrame(1);
            this.battry.setFrame(4);
          }
        } else if (this.lampTimeX - this.timedEvent.elapsed < 10000) {
          this.dark.setScale(2);
          this.battry.setFrame(2);
        } else if (this.lampTimeX - this.timedEvent.elapsed < 15000) {
          this.dark.setScale(3);
          this.battry.setFrame(1);
        } else {
          this.dark.setScale(4);
          this.battry.setFrame(0);
        }
      }
    }

    this.boneBoard.y = 60 + this.cameras.main.scrollY;
    this.boneI.y = 92 + this.cameras.main.scrollY;
    this.boneIV.y = 75 + this.cameras.main.scrollY;
    this.BoneText.y = 100 + this.cameras.main.scrollY;
    this.settingBtn.y = 40 + this.cameras.main.scrollY;
    this.scoreText.y = 40 + this.cameras.main.scrollY;
    this.roundText.y = 82 + this.cameras.main.scrollY;
    this.roundBoard.y = 80 + this.cameras.main.scrollY;
    this.scoreBoard.y = 40 + this.cameras.main.scrollY;
    this.topBoard.y = this.cameras.main.scrollY;
    this.topBoardBack.y = this.cameras.main.scrollY;
    // this.bulb.y = 150 + this.cameras.main.scrollY;

    if (
      !this.tiles[this.nextCampTileY][this.nextCampTileX].isDone &&
      this.currentTileX == this.nextCampTileX &&
      this.currentTileY == this.nextCampTileY
    ) {
      this.ideaBtn.setAlpha(1);
      if (this.timedEvent) this.timedEvent.paused = true;
    } else {
      this.ideaBtn.setAlpha(0);
      if (this.timedEvent && !this.isAfterBomb) this.timedEvent.paused = false;
    }

    if (
      (this.currentTileX == this.nextCampTileX &&
        (this.currentTileY == this.nextCampTileY ||
          this.currentTileY + 1 == this.nextCampTileY ||
          this.currentTileY - 1 == this.nextCampTileY)) ||
      (this.currentTileY == this.nextCampTileY &&
        (this.currentTileX == this.nextCampTileX ||
          this.currentTileX + 1 == this.nextCampTileX ||
          this.currentTileX - 1 == this.nextCampTileX))
    ) {
      if (this.tiles[this.nextCampTileY][this.nextCampTileX].isDone) {
        this.tiles[this.nextCampTileY][this.nextCampTileX].setFrame(3);
      } else {
        this.tiles[this.nextCampTileY][this.nextCampTileX].setFrame(1);
      }
    } else {
      if (this.tiles[this.nextCampTileY][this.nextCampTileX].isDone) {
        this.tiles[this.nextCampTileY][this.nextCampTileX].setFrame(2);
      } else {
        this.tiles[this.nextCampTileY][this.nextCampTileX].setFrame(0);
      }
    }

    if (!this.isAfterBomb) {
      if (
        (((this.currentTileX == this.currentCampTileX &&
          this.currentTileY == this.currentCampTileY) ||
          (this.currentTileX == this.nextCampTileX &&
            this.currentTileY == this.nextCampTileY)) &&
          !this.isLampPower) ||
        this.isLampPower
      ) {
        if (this.playerMask) {
          this.playerMask.destroy();
        }
        this.playerAvatarObject.forEach((element) => {
          element.setAlpha(1);
        });
        this.axe.setAlpha(1);
      } else {
        this.axe.setAlpha(0.5);
        if (this.playerMask) {
          this.playerMask.destroy();
        }
        this.playerMask = this.add
          .image(this.playerPositionX, this.playerPositionY, "maskFear")
          .setScale(0.18)
          .setDepth(7)
          .setFlipX(this.axe.flipX ? true : false);
        this.playerAvatarObject.forEach((element) => {
          element.setAlpha(0.05);
        });
      }
    }

    if (
      (this.currentTileX == this.currentCampTileX &&
        (this.currentTileY == this.currentCampTileY ||
          this.currentTileY + 1 == this.currentCampTileY ||
          this.currentTileY - 1 == this.currentCampTileY)) ||
      (this.currentTileY == this.currentCampTileY &&
        (this.currentTileX == this.currentCampTileX ||
          this.currentTileX + 1 == this.currentCampTileX ||
          this.currentTileX - 1 == this.currentCampTileX))
    ) {
      this.tiles[this.currentCampTileY][this.currentCampTileX].setFrame(3);
    } else {
      this.tiles[this.currentCampTileY][this.currentCampTileX].setFrame(2);
    }

    if (this.playerAvatarObject[0]) {
      this.playerPositionX = this.playerAvatarObject[0].x;
      this.playerPositionY = this.playerAvatarObject[0].y;
      if (this.playerPositionY > 500 + this.cameras.main.scrollY) {
        this.cameras.main.pan(
          240,
          this.playerPositionY - 90,
          500,
          "Linear",
          true
        );
      } else {
        this.cameras.main.pan(
          240,
          this.currentCampTileY + 427,
          500,
          "Linear",
          true
        );
      }
    }

    if (this.battry) {
      this.battry.x = this.playerPositionX;
      this.battry.y = this.playerPositionY - 10;
    }

    if (this.playerMask) {
      this.playerMask.x = this.playerPositionX;
      this.playerMask.y = this.playerPositionY;
    }

    if (this.lapm) {
      this.lapm.x = this.playerPositionX;
      this.lapm.y = this.playerPositionY - 5;
    }

    if (this.ideaBtn) {
      this.ideaBtn.x = this.playerPositionX;
      this.ideaBtn.y = this.playerPositionY - 80;
    }

    if (this.axe && !this.isPlayerDigging) {
      this.axe.x = this.playerPositionX;
      this.axe.y = this.playerPositionY;
    }

    if (this.dark) {
      this.dark.x = this.playerPositionX;
      this.dark.y = this.playerPositionY;
    }

    if (this.leftBtn) {
      this.leftBtn.x = this.playerPositionX + 48;
      this.leftBtn.y = this.playerPositionY;
    }

    if (this.rightBtn) {
      this.rightBtn.x = this.playerPositionX - 48;
      this.rightBtn.y = this.playerPositionY;
    }

    if (this.upBtn) {
      this.upBtn.x = this.playerPositionX;
      this.upBtn.y = this.playerPositionY - 48;
    }

    if (this.downBtn) {
      this.downBtn.x = this.playerPositionX;
      this.downBtn.y = this.playerPositionY + 48;
    }

    if (this.isPlayerMoving || this.isPlayerDigging || !this.startPlay) {
      this.leftBtn.setAlpha(0);
      this.rightBtn.setAlpha(0);
      this.upBtn.setAlpha(0);
      this.downBtn.setAlpha(0);
      // } else if (!this.tiles[this.currentTileY][this.currentTileX].isCamp) {
    } else {
      // this.leftBtn.setAlpha(1);
      // this.rightBtn.setAlpha(1);
      // this.upBtn.setAlpha(1);
      // this.downBtn.setAlpha(1);
      // if (this.currentCampTileY == this.currentTileY) {
      //   this.upBtn.setAlpha(0);
      // } else {
      //   this.upBtn.setAlpha(1);
      // }

      if (this.currentCampTileY == this.currentTileY || this.isAfterBomb) {
        this.upBtn.setAlpha(0);
      } else {
        this.upBtn.setAlpha(this.isLampPower ? 1 : 0);
      }
      if (this.isAfterBomb) {
        this.leftBtn.setAlpha(0);
      } else {
        this.leftBtn.setAlpha(this.isLampPower ? 1 : 0);
      }
      if (this.isAfterBomb) {
        this.rightBtn.setAlpha(0);
      } else {
        this.rightBtn.setAlpha(this.isLampPower ? 1 : 0);
      }
      if (this.isAfterBomb) {
        this.downBtn.setAlpha(0);
      } else {
        this.downBtn.setAlpha(this.isLampPower ? 1 : 0);
      }
    }
  }

  addItems() {
    this.items = [];
    let dangers = [];
    let startIndex;
    let collecables = [];

    if (this.currentCampTileX > 0) {
      startIndex = Math.floor(Math.random() * this.currentCampTileX);
    } else {
      startIndex =
        this.currentCampTileX +
        Math.floor(Math.random() * (4 - this.currentCampTileX));
    }
    for (
      let index = 0;
      startIndex < this.nextCampTileY * 5 + this.nextCampTileX;
      index++
    ) {
      dangers.push(startIndex);
      startIndex = index % 2 == 0 ? startIndex + 3 : startIndex + 4;
    }

    for (let index = 0; index < 35; index++) {
      // if (
      //   index != this.nextCampTileY * 5 + this.nextCampTileX &&
      //   index != this.currentCampTileY * 5 + this.currentCampTileX &&
      //   !dangers.includes(index)
      // ) {
      //   collecables.push(index);
      // }
      if (index > 4 && index < 30 && !dangers.includes(index)) {
        collecables.push(index);
      }
    }

    // bombs 4 - 5
    let bombcount = Math.floor(Math.random() * 2) + 4;
    bombcount = 5;
    for (let index = 0; index < bombcount; index++) {
      this.items[
        dangers.splice(Math.floor(Math.random() * dangers.length), 1)[0]
      ] = "BOMB";
    }

    // rocks 2 - 4
    let rockcount = Math.floor(Math.random() * 3) + 2;
    rockcount = 3;
    for (let index = 0; index < rockcount; index++) {
      this.items[
        dangers.splice(Math.floor(Math.random() * dangers.length), 1)[0]
      ] = "ROCK";
    }

    // total 7 - 10 type I bones so 1 - 2 in each round
    let boneTypeIcount = Math.floor(Math.random() * 2) + 1;
    boneTypeIcount = 1;
    for (let index = 0; index < boneTypeIcount; index++) {
      this.items[
        collecables.splice(
          Math.floor(Math.random() * Math.floor(collecables.length / 2)),
          1
        )[0]
      ] = "BONE_I";
    }

    // total 15 - 20 type II bones so 2 - 3 in each round
    let boneTypeIIcount = Math.floor(Math.random() * 2) + 2;
    boneTypeIIcount = 1;
    for (let index = 0; index < boneTypeIIcount; index++) {
      this.items[
        collecables.splice(
          Math.floor(Math.random() * Math.floor(collecables.length / 2)) +
            Math.floor(collecables.length / 2),
          1
        )[0]
      ] = "BONE_II";
    }
    // skull 1 only after round 5

    // type I gem 2 - 4 score 3
    let gemTypeIcount = Math.floor(Math.random() * 2) + 2;
    for (let index = 0; index < gemTypeIcount; index++) {
      this.items[
        collecables.splice(Math.floor(Math.random() * collecables.length), 1)[0]
      ] = "GEM_I";
    }

    // type II gem 1 - 3 score 5
    let gemTypeIIcount = Math.floor(Math.random() * 2) + 2;
    for (let index = 0; index < gemTypeIIcount; index++) {
      this.items[
        collecables.splice(Math.floor(Math.random() * collecables.length), 1)[0]
      ] = "GEM_II";
    }

    // Energy 1 score 5
    let energycount = 1;
    for (let index = 0; index < energycount; index++) {
      this.items[
        collecables.splice(Math.floor(Math.random() * collecables.length), 1)[0]
      ] = "ENERGY";
    }

    if (this.items.length < 35) {
      this.items[34] = undefined;
    }
  }

  shuffleArray(exceptIndices) {
    let shuffledArray = [...this.items];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      if (!exceptIndices.includes(i)) {
        const j = Math.floor(Math.random() * (i + 1));
        if (!exceptIndices.includes(j)) {
          [shuffledArray[i], shuffledArray[j]] = [
            shuffledArray[j],
            shuffledArray[i],
          ];
        }
      }
    }
    this.items = shuffledArray;
  }

  moveLeft() {
    if (this.tiles[this.currentTileY][this.currentTileX + 1].state == 1)
      this.tweens.add({
        targets: this.playerAvatarObject,
        x: "+=96",
        duration: 500,
        ease: "Sine.easeInOut",
        onStart: () => {
          this.playerTweenX = this.tweens.add({
            targets: this.playerAvatarObject,
            scaleY: "+=0.01",
            scaleX: "-=0.01",
            duration: 100,
            ease: "Sine.easeInOut",
            repeat: -1,
            yoyo: true,
          });
        },
        onComplete: () => {
          this.currentTileX += 1;
          this.playerTweenX.stop();
          this.afterMove();
        },
      });
    else
      this.time.delayedCall(200, () => {
        this.isPlayerMoving = false;
      });
  }

  digLeft() {
    if (!this.isStartTimer) {
      this.isStartTimer = true;
      this.startTimer(this.lampTimeX);
    }
    this.playerAvatarObject.forEach((element) => {
      element.setFlipX(false);
    });
    this.lapm.setFlipX(false);
    this.axe.setFlipX(false);
    if (this.playerMask) {
      this.playerMask.setFlipX(false);
    }
    this.isPlayerMoving = true;
    if (this.tiles[this.currentTileY][this.currentTileX + 1].state == 1) {
      this.moveLeft();
    } else {
      this.isPlayerDigging = true;
      this.axe.x = this.playerPositionX + 25;
      this.tweens.add({
        targets: this.axe,
        angle: "-=40",
        duration: 100,
        ease: "Sine.easeInOut",
        repeat: 1,
        yoyo: true,
        onComplete: () => {
          this.isPlayerDigging = false;

          if (
            this.tiles[this.currentTileY][this.currentTileX + 1].itemType &&
            this.tiles[this.currentTileY][this.currentTileX + 1].itemType ==
              "ROCK"
          ) {
            if (
              this.tiles[this.currentTileY][this.currentTileX + 1].state == 2
            ) {
              this.tiles[this.currentTileY][this.currentTileX + 1].state = 1;
              this.tiles[this.currentTileY][this.currentTileX + 1].setFrame(1);
              this.tiles[this.currentTileY][this.currentTileX + 1].itemType =
                undefined;
              this.tiles[this.currentTileY][
                this.currentTileX + 1
              ].item.destroy();
            } else {
              this.tiles[this.currentTileY][this.currentTileX + 1].state = 2;
              this.tiles[this.currentTileY][
                this.currentTileX + 1
              ].item.setFrame(1);
            }
          } else {
            this.tiles[this.currentTileY][this.currentTileX + 1].state = 1;
            this.tiles[this.currentTileY][this.currentTileX + 1].setFrame(1);
            if (this.tiles[this.currentTileY][this.currentTileX + 1].itemType) {
              this.tiles[this.currentTileY][
                this.currentTileX + 1
              ].item.setFrame(1);
            }
          }
          this.moveLeft();
        },
      });
    }
  }

  moveRight() {
    if (this.tiles[this.currentTileY][this.currentTileX - 1].state == 1)
      this.tweens.add({
        targets: this.playerAvatarObject,
        x: "-=96",
        duration: 500,
        ease: "Sine.easeInOut",
        onStart: () => {
          this.playerTweenX = this.tweens.add({
            targets: this.playerAvatarObject,
            scaleY: "+=0.01",
            scaleX: "-=0.01",
            duration: 100,
            ease: "Sine.easeInOut",
            repeat: -1,
            yoyo: true,
          });
        },
        onComplete: () => {
          this.currentTileX -= 1;
          this.playerTweenX.stop();
          this.afterMove();
        },
      });
    else
      this.time.delayedCall(200, () => {
        this.isPlayerMoving = false;
      });
  }

  digRight() {
    if (!this.isStartTimer) {
      this.isStartTimer = true;
      this.startTimer(this.lampTimeX);
    }
    this.playerAvatarObject.forEach((element) => {
      element.setFlipX(true);
    });
    this.lapm.setFlipX(true);
    this.axe.setFlipX(true);
    this.isPlayerMoving = true;
    if (this.playerMask) {
      this.playerMask.setFlipX(true);
    }

    if (this.tiles[this.currentTileY][this.currentTileX - 1].state == 1) {
      this.moveRight();
    } else {
      this.isPlayerDigging = true;
      this.axe.x = this.playerPositionX - 25;
      this.axe.y = this.playerPositionY;
      this.tweens.add({
        targets: this.axe,
        angle: "+=40",
        duration: 100,
        ease: "Sine.easeInOut",
        repeat: 1,
        yoyo: true,
        onComplete: () => {
          this.isPlayerDigging = false;
          if (
            this.tiles[this.currentTileY][this.currentTileX - 1].itemType &&
            this.tiles[this.currentTileY][this.currentTileX - 1].itemType ==
              "ROCK"
          ) {
            if (
              this.tiles[this.currentTileY][this.currentTileX - 1].state == 2
            ) {
              this.tiles[this.currentTileY][this.currentTileX - 1].state = 1;
              this.tiles[this.currentTileY][this.currentTileX - 1].setFrame(1);
              this.tiles[this.currentTileY][this.currentTileX - 1].itemType =
                undefined;
              this.tiles[this.currentTileY][
                this.currentTileX - 1
              ].item.destroy();
            } else {
              this.tiles[this.currentTileY][this.currentTileX - 1].state = 2;
              this.tiles[this.currentTileY][
                this.currentTileX - 1
              ].item.setFrame(1);
            }
          } else {
            this.tiles[this.currentTileY][this.currentTileX - 1].state = 1;
            this.tiles[this.currentTileY][this.currentTileX - 1].setFrame(1);
            if (this.tiles[this.currentTileY][this.currentTileX - 1].itemType) {
              this.tiles[this.currentTileY][
                this.currentTileX - 1
              ].item.setFrame(1);
            }
          }
          this.moveRight();
        },
      });
    }
  }

  moveUp() {
    if (this.tiles[this.currentTileY - 1][this.currentTileX].state == 1)
      this.tweens.add({
        targets: this.playerAvatarObject,
        y: "-=96",
        duration: 500,
        ease: "Sine.easeInOut",
        onStart: () => {
          this.playerTweenX = this.tweens.add({
            targets: this.playerAvatarObject,
            scaleY: "+=0.01",
            scaleX: "-=0.01",
            duration: 100,
            ease: "Sine.easeInOut",
            repeat: -1,
            yoyo: true,
          });
        },
        onComplete: () => {
          this.currentTileY -= 1;
          this.playerTweenX.stop();
          this.afterMove();
        },
      });
    else
      this.time.delayedCall(200, () => {
        this.isPlayerMoving = false;
      });
  }

  digUp() {
    if (!this.isStartTimer) {
      this.isStartTimer = true;
      this.startTimer(this.lampTimeX);
    }
    this.isPlayerMoving = true;
    if (this.tiles[this.currentTileY - 1][this.currentTileX].state == 1) {
      this.moveUp();
    } else {
      this.isPlayerDigging = true;
      this.axe.y = this.playerPositionY - 70;
      this.axe.x = this.axe.flipX
        ? this.playerPositionX + 20
        : this.playerPositionX - 20;
      this.tweens.add({
        targets: this.axe,
        angle: this.axe.flipX ? "+=40" : "-=40",
        duration: 100,
        ease: "Sine.easeInOut",
        repeat: 1,
        yoyo: true,
        onComplete: () => {
          this.isPlayerDigging = false;
          if (
            this.tiles[this.currentTileY - 1][this.currentTileX].itemType &&
            this.tiles[this.currentTileY - 1][this.currentTileX].itemType ==
              "ROCK"
          ) {
            if (
              this.tiles[this.currentTileY - 1][this.currentTileX].state == 2
            ) {
              this.tiles[this.currentTileY - 1][this.currentTileX].state = 1;
              this.tiles[this.currentTileY - 1][this.currentTileX].setFrame(1);
              this.tiles[this.currentTileY - 1][this.currentTileX].itemType =
                undefined;
              this.tiles[this.currentTileY - 1][
                this.currentTileX
              ].item.destroy();
            } else {
              this.tiles[this.currentTileY - 1][this.currentTileX].state = 2;
              this.tiles[this.currentTileY - 1][
                this.currentTileX
              ].item.setFrame(1);
            }
          } else {
            this.tiles[this.currentTileY - 1][this.currentTileX].state = 1;
            this.tiles[this.currentTileY - 1][this.currentTileX].setFrame(1);
            if (this.tiles[this.currentTileY - 1][this.currentTileX].itemType) {
              this.tiles[this.currentTileY - 1][
                this.currentTileX
              ].item.setFrame(1);
            }
          }
          this.moveUp();
        },
      });
    }
  }

  moveDown() {
    if (this.tiles[this.currentTileY + 1][this.currentTileX].state == 1)
      this.tweens.add({
        targets: this.playerAvatarObject,
        y: "+=96",
        duration: 500,
        ease: "Sine.easeInOut",
        onStart: () => {
          this.playerTweenX = this.tweens.add({
            targets: this.playerAvatarObject,
            scaleY: "+=0.01",
            scaleX: "-=0.01",
            duration: 100,
            ease: "Sine.easeInOut",
            repeat: -1,
            yoyo: true,
          });
        },
        onComplete: () => {
          this.currentTileY += 1;
          this.playerTweenX.stop();
          this.afterMove();
        },
      });
    else
      this.time.delayedCall(200, () => {
        this.isPlayerMoving = false;
      });
  }

  digDown() {
    if (!this.isStartTimer) {
      this.isStartTimer = true;
      this.startTimer(this.lampTimeX);
    }
    this.isPlayerMoving = true;
    if (this.tiles[this.currentTileY + 1][this.currentTileX].state == 1) {
      this.moveDown();
    } else {
      this.isPlayerDigging = true;
      this.axe.y = this.playerPositionY + 50;
      this.axe.x = this.axe.flipX
        ? this.playerPositionX + 20
        : this.playerPositionX - 20;

      this.tweens.add({
        targets: this.axe,
        angle: this.axe.flipX ? "-=40" : "+=40",
        duration: 100,
        ease: "Sine.easeInOut",
        repeat: 1,
        yoyo: true,
        onComplete: () => {
          this.isPlayerDigging = false;
          if (
            this.tiles[this.currentTileY + 1][this.currentTileX].itemType &&
            this.tiles[this.currentTileY + 1][this.currentTileX].itemType ==
              "ROCK"
          ) {
            if (
              this.tiles[this.currentTileY + 1][this.currentTileX].state == 2
            ) {
              this.tiles[this.currentTileY + 1][this.currentTileX].state = 1;
              this.tiles[this.currentTileY + 1][this.currentTileX].setFrame(1);
              this.tiles[this.currentTileY + 1][this.currentTileX].itemType =
                undefined;
              this.tiles[this.currentTileY + 1][
                this.currentTileX
              ].item.destroy();
            } else {
              this.tiles[this.currentTileY + 1][this.currentTileX].state = 2;
              this.tiles[this.currentTileY + 1][
                this.currentTileX
              ].item.setFrame(1);
            }
          } else {
            this.tiles[this.currentTileY + 1][this.currentTileX].state = 1;
            this.tiles[this.currentTileY + 1][this.currentTileX].setFrame(1);
            if (this.tiles[this.currentTileY + 1][this.currentTileX].itemType) {
              this.tiles[this.currentTileY + 1][
                this.currentTileX
              ].item.setFrame(1);
            }
          }
          this.moveDown();
        },
      });
    }
  }

  afterMove() {
    if (this.tiles[this.currentTileY][this.currentTileX].itemType) {
      this.time.delayedCall(400, () => {
        this.isPlayerMoving = false;
      });
      if (this.tiles[this.currentTileY][this.currentTileX].itemType == "BOMB") {
        let newTime = Math.floor(this.timedEvent.getOverallRemaining()) - 2000;
        this.timedEvent.paused = true;
        this.timedEvent.elapsed = this.lampTimeX - newTime;

        this.scoreIndication(
          this.playerPositionX,
          this.playerPositionY,
          10,
          false
        );

        this.tiles[this.currentTileY][this.currentTileX].itemType = undefined;
        this.tiles[this.currentTileY][this.currentTileX].item.destroy();

        let mgob = this.add
          .sprite(this.playerPositionX, this.playerPositionY, "sptBlast")
          .setScale(0.7)
          .setDepth(6)
          .setFrame(7);

        mgob.play("animBlast");
        if (this.playerMask) {
          this.playerMask.destroy();
        }
        this.playerMask = this.add
          .image(this.playerPositionX, this.playerPositionY, "maskScold")
          .setScale(0.18)
          .setDepth(7)
          .setFlipX(this.axe.flipX ? true : false);
        this.isAfterBomb = true;
        mgob.on("animationcomplete", () => {
          this.tweens.add({
            targets: this.playerAvatarObject,
            alpha: 0,
            duration: 100,
            yoyo: true,
            repeat: 3,
            onComplete: () => {
              this.isAfterBomb = false;
              this.timedEvent.paused = false;

              // this.startTimer(newTime);
              // this.currentTileX = this.currentCampTileX;
              // this.currentTileY = this.currentCampTileY;
              // this.playerAvatarObject.forEach((element) => {
              //   element.x = 48 + 96 * this.currentTileX;
              //   element.y = 230 + 96 * this.currentTileY;
              // });
              // this.axe.x = 48 + 96 * this.currentTileX;
              // this.axe.y = 230 + 96 * this.currentTileY;
            },
          });
          mgob.destroy();
          if (this.playerMask) {
            this.playerMask.destroy();
          }
          if (!this.isLampPower) {
            this.playerMask = this.add
              .image(this.playerPositionX, this.playerPositionY, "maskFear")
              .setScale(0.18)
              .setDepth(7)
              .setFlipX(this.axe.flipX ? true : false);
          }
        });
      }
      if (
        this.tiles[this.currentTileY][this.currentTileX].itemType == "ENERGY"
      ) {
        this.tiles[this.currentTileY][this.currentTileX].itemType = undefined;
        this.tiles[this.currentTileY][this.currentTileX].item.destroy();

        let newTime = Math.floor(this.timedEvent.getOverallRemaining()) + 5000;
        // this.timedEvent.paused = true;
        this.timedEvent.elapsed =
          this.lampTimeX > newTime ? this.lampTimeX - newTime : 0;

        console.log(this.timedEvent.elapsed);
      }
      if (
        this.tiles[this.currentTileY][this.currentTileX].itemType == "BONE_I"
      ) {
        this.collectedBones++;
        this.BoneText.setText(this.collectedBones + "/" + this.totalBones);
        this.tiles[this.currentTileY][this.currentTileX].itemType = undefined;
        this.tiles[this.currentTileY][this.currentTileX].item.destroy();
        if (this.tiles[this.currentTileY][this.currentTileX].item.blink) {
          this.tiles[this.currentTileY][this.currentTileX].item.blink.destroy();
        }
        this.scoreIndication(this.playerPositionX, this.playerPositionY, 10);
      }
      if (
        this.tiles[this.currentTileY][this.currentTileX].itemType == "BONE_II"
      ) {
        if (this.tiles[this.currentTileY][this.currentTileX].item.isSkull) {
          this.collectedSkull++;
        }
        this.collectedBones++;
        this.BoneText.setText(this.collectedBones + "/" + this.totalBones);
        this.tiles[this.currentTileY][this.currentTileX].itemType = undefined;
        this.tiles[this.currentTileY][this.currentTileX].item.destroy();
        if (this.tiles[this.currentTileY][this.currentTileX].item.blink) {
          this.tiles[this.currentTileY][this.currentTileX].item.blink.destroy();
        }
        this.scoreIndication(this.playerPositionX, this.playerPositionY, 10);
      }
      if (
        this.tiles[this.currentTileY][this.currentTileX].itemType == "GEM_I"
      ) {
        this.tiles[this.currentTileY][this.currentTileX].itemType = undefined;
        this.tiles[this.currentTileY][this.currentTileX].item.destroy();
        this.scoreIndication(this.playerPositionX, this.playerPositionY, 10);
      }
      if (
        this.tiles[this.currentTileY][this.currentTileX].itemType == "GEM_II"
      ) {
        this.tiles[this.currentTileY][this.currentTileX].itemType = undefined;
        this.tiles[this.currentTileY][this.currentTileX].item.destroy();
        this.scoreIndication(this.playerPositionX, this.playerPositionY, 10);
      }
    } else {
      this.time.delayedCall(100, () => {
        this.isPlayerMoving = false;
      });
    }
  }

  registerSounds() {
    this.sfxSimpleClick = this.sound.add("sfxSimpleClick");
    this.sfxRound = this.sound.add("sfxRound");
    this.sfxStart = this.sound.add("sfxStart");
  }

  registerAnims() {
    this.anims.create({
      key: "animIdea",
      frames: this.anims.generateFrameNumbers("sptIdea", {
        frames: [0, 1],
      }),
      repeat: -1,
      frameRate: 5,
    });
    this.anims.create({
      key: "animIdea2",
      frames: this.anims.generateFrameNumbers("sptIdea2", {
        frames: [0, 1],
      }),
      repeat: -1,
      frameRate: 5,
    });

    this.anims.create({
      key: "animBlast",
      frames: this.anims.generateFrameNumbers("sptBlast", {
        frames: [0, 1, 2, 3, 4, 4, 4, 4, 4, 4, 4, 5, 6, 7],
      }),
      frameRate: 15,
    });

    this.anims.create({
      key: "animFlicker1",
      frames: this.anims.generateFrameNumbers("sptDark", {
        frames: [4, 0, 4, 0, 1],
      }),
      frameRate: 10,
    });
    this.anims.create({
      key: "animFlicker2",
      frames: this.anims.generateFrameNumbers("sptDark", {
        frames: [4, 1, 4, 1, 2],
      }),
      frameRate: 10,
    });
    this.anims.create({
      key: "animFlicker3",
      frames: this.anims.generateFrameNumbers("sptDark", {
        frames: [4, 2, 4, 2, 3],
      }),
      frameRate: 10,
    });
    this.anims.create({
      key: "animFlicker4",
      frames: this.anims.generateFrameNumbers("sptDark", {
        frames: [4, 3, 4, 3, 4],
      }),
      frameRate: 10,
    });
  }

  goQuestion() {
    if (this.round == this.noOfRounds) {
      this.time.delayedCall(500, () => {
        this.scene.start("MachineScene", {
          avatar: this.avatar,
          name: this.name,
          round: this.round,
          score: this.score,
          questions: this.questions,
          friend: this.friend,
          difficulty: this.difficulty,
          countCorrect: this.countCorrect,
          noOfRounds: this.noOfRounds,
          noOfCuts: this.noOfCuts,
          bossRound: this.bossRound,
          bossImage: this.bossImage,
          currentCampTileX: this.nextCampTileX,
          nextCampTileX: this.nextNextCampTileX,
          totalBones: this.totalBones,
          collectedBones: this.collectedBones,
          collectedSkull: this.collectedSkull,
          lampTime: this.lampTime,
        });
      });
    } else {
      this.dark.destroy();
      this.playerAvatarObject = [];
      this.registry.destroy();
      this.events.off();
      this.scene.stop("PlayScene");
      this.timedEvent.destroy();
      this.timedEvent = null;

      this.scene.start("QuestionScene", {
        avatar: this.avatar,
        name: this.name,
        round: this.round,
        score: this.score,
        questions: this.questions,
        friend: this.friend,
        difficulty: this.difficulty,
        countCorrect: this.countCorrect,
        noOfRounds: this.noOfRounds,
        noOfCuts: this.noOfCuts,
        bossRound: this.bossRound,
        bossImage: this.bossImage,
        currentCampTileX: this.nextCampTileX,
        nextCampTileX: this.nextNextCampTileX,
        totalBones: this.totalBones,
        collectedBones: this.collectedBones,
        collectedSkull: this.collectedSkull,
        lampTime: this.lampTime,
      });
    }
  }

  clickSetting() {
    this.sfxSimpleClick.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxSimpleClick.play();
    this.scene.pause();
    this.scene.launch("Settings", {
      sceneName: "PlayScene",
    });
  }

  scoreIndication(x, y, score, increase = true) {
    let indication = this.add
      .text(x, y - 50, increase ? "+ " + score : "- " + score, {
        color: increase ? "#fff" : "#ff0000",
        fontSize: "20px",
        fontFamily: "Sassoon, sans-serif",
      })
      .setOrigin(0.5)
      .setDepth(9);
    this.tweens.add({
      targets: indication,
      alpha: 0,
      y: "-=50",
      duration: 500,
      ease: "Sine.easeInOut",
      onStart: () => {
        // this.sfxScoreIncrease.setVolume(
        //   store.state.settings.data.audio.sfx *
        //     store.state.settings.data.audio.master
        // );
        // this.sfxScoreIncrease.play();
      },
      onComplete: () => {
        indication.destroy();
        this.score += score;
        this.scoreText.setText(
          i18n.t("miniGames.general.score") + " : " + this.score
        );
      },
    });
  }
}
