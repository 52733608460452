import { Scene } from "phaser";
import store from "@/store";
import i18n from "@/plugins/i18n";
import _ from "lodash";
// import axios from "axios";

export default class WearItem extends Scene {
  constructor() {
    super({ key: "WearItem" });
  }

  init(data) {
    this.sceneName = data.sceneName;
    this.avatar = data.avatar;
    this.ladder = data.ladder;
    this.blueStar = data.blueStar;
    this.game = data.game;
    this.end = data.end;
    this.wizard = data.wizard;
    this.questions = data.questions;
    this.timestampReset = data.timestampReset;
    this.colors = data.colors,
    this.env = data.env;
    this.schoolYear = data.schoolYear,
    this.classroomMonth = data.classroomMonth,
    this.isHasSpecialItem = data.isHasSpecialItem
  }

  preload() {
   
  }
  avatarObject = [];
  create() {

    // Sounds
    this.simpleClickSound = this.sound.add("simpleClick");
    this.sfxSurprise = this.sound.add("sfxSurprise");
    this.sfxPopUp = this.sound.add("sfxPopUp");

    this._backgroundImage = this.add
    .image(0, 0, "imgBackground")
    .setDisplaySize(480, 854)
    .setOrigin(0);

    this._backgroundImageBurst = this.add
    .image(0, 0, "imgBurstBackground")
    .setDisplaySize(480, 854)
    .setOrigin(0).setAlpha(0.5);
   
  const centerX = this.cameras.main.centerX;
  const centerY = this.cameras.main.centerY - 50;
  const hatData = store.state.apparel.data
  var avatarHat = hatData.filter(function (entry) {
    return entry.key == 'S1002_H';
  });
  const avatar = _.cloneDeep(this.avatar)
 
  
  console.log(avatar)
  console.log(JSON.parse(avatarHat[0].images).apparel.url)
for (let key of Object.keys(avatar)) {
      if (avatar[key]) {
        if (key == "body") {
          this.avatarObject.push(
            this.physics.add
              .sprite(centerX, centerY, key)
              .setScale(0.5)
              .setCollideWorldBounds(true)
              .setDepth(1)
          );
          this.avatarObject.push(
            this.physics.add
              .sprite(centerX, centerY, key)
              .setTint("0x" + avatar.color)
              .setScale(0.5)
              .setAlpha(0.3)
              .setCollideWorldBounds(true)
              .setDepth(1)
          );
        }
         else if (key != "color") {
          this.avatarObject.push(
            this.physics.add
              .sprite(centerX, centerY, key)
              .setScale(0.5)
              .setCollideWorldBounds(true)
              .setDepth(1)
          );
        }
      }
    }
    this.okBtn = this.add.image(240, 580, "okBtnImage").setScale(0.6).setInteractive({ cursor: "pointer" }).on("pointerdown", this.goHome, this);
    this.okBtnTxt = this.add
      .text(238, 580, i18n.t("action.ok"), {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 35,
        color: "#000",
      })
      .setOrigin(0.5)
      .setScale(1)
  this.sfxPopUp.setVolume(
    store.state.settings.data.audio.sfx *
      store.state.settings.data.audio.master
  );
  this.sfxPopUp.play();
   
   

  }

  goHome(){
    this.scene.stop("CollectItem");
    this.scene.start(this.sceneName, {
      avatar: this.avatar,
      ladder: this.ladder,
      blueStar: this.blueStar,
      game: this.game,
      end: this.end,
      wizard: this.wizard,
      questions: this.questions,
      colors: this.colors,
      env: this.env,
      timestampReset:this.timestampReset,
      schoolYear: this.schoolYear,
      classroomMonth: this.classroomMonth,
      isHasSpecialItem:this.isHasSpecialItem,
    });
  }

  update() {
    //
  }
}
