import { Scene } from "phaser";
import store from "@/store";
import axios from "axios";
import moment from "moment";
import i18n from "@/plugins/i18n";

export default class CollectItem extends Scene {
  constructor() {
    super({ key: "CollectItem" });
  }

  init(data) {
    this.type = data.type,
    this.sceneName = data.sceneName;
    this.itemKey = data.itemKey;
    this.itemCount = data.itemCount
    this.avatar = data.avatar;
    this.ladder = data.ladder;
    this.blueStar = data.blueStar;
    this.game = data.game;
    this.end = data.end;
    this.wizard = data.wizard;
    this.questions = data.questions;
    this.timestampReset = data.timestampReset
    this.colors = data.colors, 
    this.env = data.env;
    this.schoolYear = data.schoolYear,
    this.classroomMonth = data.classroomMonth
    this.isHasSpecialItem = data.isHasSpecialItem
  }

  preload() {
   
  }

  create() {

    // Sounds
    this.closeSound = this.sound.add("close");
    this.simpleClickSound = this.sound.add("simpleClick");
    this.sfxCollectItem = this.sound.add("sfxCollectItem");
    this.sfxPopUp = this.sound.add("sfxPopUp");
    this.sfxEmpty = this.sound.add("sfxEmpty");

   

    this.add.rectangle(240, 427, 480, 854, 0x000000).setAlpha(0.5);
    this.add.image(240, 430, "imgCollectPanel").setDisplaySize(600, 400).setScale(1);
   this.add.image(240, 230, this.itemCount ==="+0" ? "mochiFreeze" : "mochiRainbow").setDisplaySize(300, 300).setScale(0.21);
   //this.add.image(240, 230, "mochiRainbow").setDisplaySize(300, 300).setScale(0.21);
   

    if(this.itemCount ==="+0"){
      this.sfxEmpty.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.sfxEmpty.play();
      const words = i18n.t("view.PageBuildingMission.rewardNotReceivedMessage").split(" ");
      const lines = [];
    
      while (words.length > 0) {
        lines.push(words.splice(0, 2).join(" "));
      }
      const centerX = this.cameras.main.centerX;
      const centerY = this.cameras.main.centerY;
      lines.map((line, index) => {
        const text = this.add.text(centerX, centerY + (index - (lines.length - 1) / 2) * 30, line, {
          fontFamily: "Sassoon, sans-serif",
        fontSize: 30,
        color: "#000000",
        });
    
        // Center the text
        text.setOrigin(0.5, 0.5).setFontStyle("bold")
        .setDepth(2);
    
        return text;
      });

      // this.resetText = this.add
      // .text(0, 430, i18n.t("view.PageBuildingMission.rewardNotReceivedMessage"), {
      //   fontFamily: "Sassoon, sans-serif",
      //   fontSize: 30,
      //   color: "#000000",
      // })
      // .setOrigin(0, 0.5)
      // .setFontStyle("bold")
      // .setDepth(2);
      // let textWidth = this.resetText.width;
      // this.resetText.setX(240 - textWidth / 2);
     // this.add.image(240, 450, "mochiSad").setScale(0.19);
    }else{
      this.sfxPopUp.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.sfxPopUp.play();
      this.addItem()
    }
   
    this.okBtn = this.add.image(240, 580, "okBtnImage").setScale(0.6).setInteractive({ cursor: "pointer" }).on("pointerdown",this.itemKey=='footprint'? this.collectFoot : this.goHome, this);
    this.okBtnTxt = this.add
      .text(238, 580, i18n.t("action.ok"), {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 35,
        color: "#000",
      })
      .setOrigin(0.5)
      .setScale(1)
    
   
  }
  addItem(){
    this.resetText = this.add
    .text(0, 330, i18n.t("view.PageBuildingMission.rewardReceivedMessage"), {
      fontFamily: "Sassoon, sans-serif",
      fontSize: 30,
      color: "#000000",
    })
    .setOrigin(0, 0.5)
    .setFontStyle("bold")
    .setDepth(2);
    let textWidth = this.resetText.width;
    this.resetText.setX(240 - textWidth / 2);
    this.add.image(240, 450, "itemFrame").setScale(0.6);
    this.add.image(240, 425, "" + this.itemKey).setDisplaySize(300, 300).setScale(1);
   this.itemText = this.add
    .text(0, 506, ""+ this.itemCount, {
      fontFamily: "Sassoon, sans-serif",
      fontSize: 25,
      color: "#000000",
    })
    .setOrigin(0, 0.5)
    .setFontStyle("bold")
    .setDepth(2);
   let textCountWidth = this.itemText.width;
    this.itemText.setX(240 - textCountWidth / 2);
  }
  goHome(){

    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
   if(this.itemCount !="+0"){
    this.sfxCollectItem.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxCollectItem.play();
   }
  
    this.scene.stop("CollectItem");
    if(this.type == 'completionGift'){
      this.scene.resume(this.sceneName);
    }else{
      this.scene.start(this.sceneName, {
        avatar: this.avatar,
        ladder: this.ladder,
        blueStar: this.blueStar,
        game: this.game,
        end: this.end,
        wizard: this.wizard,
        questions: this.questions,
        colors: this.colors,
        env: this.env,
        timestampReset:this.timestampReset,
        schoolYear: this.schoolYear,
        classroomMonth: this.classroomMonth,
        isHasSpecialItem:this.isHasSpecialItem,
      });
    }
      
  }
  collectFoot() {
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
    this.okBtn.off('pointerdown', this.collectFoot, this).removeInteractive();
    this.sfxCollectItem.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxCollectItem.play();
    let token = store.state.auth.data.Session.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(
        `${this.env.VUE_APP_URL_API_GAME}/api/v1/en/game/footprint/reset`,
        {},
        { headers }
      )
      .then((response) => {
        // Process the data returned from the API
        this.ladder = response.data.Footprint.quantity;
        this.timestampReset = moment(response.data.TimestampReset);
        this.scene.stop("CollectItem");
        this.scene.start(this.sceneName, {
          avatar: this.avatar,
          ladder: this.ladder,
          blueStar: this.blueStar,
          game: this.game,
          end: this.end,
          wizard: this.wizard,
          questions: this.questions,
          colors: this.colors,
          env: this.env,
          timestampReset:this.timestampReset,
          schoolYear: this.schoolYear,
          classroomMonth: this.classroomMonth,
          isHasSpecialItem:this.isHasSpecialItem,
        });
    
  //  this.scene.resume(this.sceneName);
      })
      .catch((error) => {
        // Handle any errors that occur during the API call
        console.error("Error:", error);
      });
  }
  clickClose() {
    this.scene.stop("CollectItem");
    this.scene.resume(this.sceneName);
  }
  update() {
    //
  }
}
