import { Scene } from "phaser";
import store from "@/store/index.js";
import i18n from "@/plugins/i18n";
// import router from "@/router";

export default class Recommendation extends Scene {
  constructor() {
    super({ key: "Recommendation" });
  }

  init(data) {
    this.sceneName = data.sceneName;
    this.youtubeUrl = data.youtubeUrl;
  }

  preload() {
    //
  }

  create() {
    var watchBtn;
    var watch;
    var continueBtn;
    var continueTxt;
    var closeBtn;

    // Sounds
    this.closeSound = this.sound.add("sfxClose");
    this.simpleClickSound = this.sound.add("sfxSimpleClick");

    this.add.rectangle(240, 427, 480, 854, 0x000000).setAlpha(0.7);
    this.add.image(240, 350, "imgSettingPanel").setScale(1.1, 1.4);

    closeBtn = this.add.image(360, 180, "imgCloseBtn").setScale(0.3);
    closeBtn.on("pointerdown", clickClose, this);
    closeBtn.setInteractive({ useHandCursor: true });

    this.add.image(240, 250, "imgHamochiVideo").setScale(0.3);

    this.add
      .text(240, 360, i18n.t("miniGames.general.recommendationTxt"), {
        fontSize: "20px",
        wordWrap: { width: 250 },
        fontFamily: "Sassoon, sans-serif",
        color: "#000000",
        align: "center",
      })
      .setOrigin(0.5);

    watchBtn = this.add.image(240, 425, "imgOptionBtn").setScale(0.35);
    watch = this.add
      .text(240, 425, i18n.t("miniGames.general.recommendationBtn"), {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 60,
        color: "#000",
        align: "center",
      })
      .setScale(0.35);
    watch.on("pointerdown", clickWatch, this);
    watch.setOrigin(0.5);
    watch.setPadding(20);
    watch.setInteractive({ useHandCursor: true });
    var watchTween = this.tweens.add({
      targets: [watchBtn, watch],
      scaleX: 0.36,
      scaleY: 0.36,
      duration: 500,
      repeat: -1,
      yoyo: true,
    });


    continueBtn = this.add.image(240, 495, "imgOptionBtn").setScale(0.35);
    continueTxt = this.add
      .text(240, 495, i18n.t("action.continue"), {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 60,
        color: "#000",
        align: "center",
      })
      .setScale(0.35);
    continueTxt.on("pointerdown", clickClose, this);
    continueTxt.setOrigin(0.5);
    continueTxt.setPadding(20);
    continueTxt.setInteractive({ useHandCursor: true });

    function clickClose() {
      this.closeSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.closeSound.play();
      this.scene.stop("Recommendation");
      this.scene.resume(this.sceneName);
    }

    function clickWatch() {
      this.simpleClickSound.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.simpleClickSound.play();

      watchTween.stop()
      
      this.tweens.add({
        targets: [continueBtn, continueTxt],
        scaleX: 0.36,
        scaleY: 0.36,
        duration: 500,
        repeat: -1,
        yoyo: true,
      });

      // router.push({
      //   name: "PageEventLevelYoutube",
      //   params: {
      //     id: store.state.event.data.record.levelId,
      //   },
      // });

      window.open(
        this.youtubeUrl,
        "_blank"
      );
    }
  }

  update() {
    //
  }
}
