import { Scene } from "phaser";
import i18n from "@/plugins/i18n";

export default class LoadingScene extends Scene {
  constructor() {
    super({ key: "LoadingScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.name = data.name;
    this.questions = data.questions;
    this.friend = data.friend;
    this.bossRound = data.bossRound;
    this.bossImage = data.bossImage;
    this.env = data.env;
    this.noOfRounds = data.noOfRounds;
    this.difficulty = data.difficulty;
    this.bossName = data.bossName;
  }

  preload() {
    var progressBar = this.add.graphics();
    var progressBox = this.add.graphics();
    progressBox.fillStyle(0x222222, 0.8);
    progressBox.fillRect(100, 270, 280, 50);

    var width = this.cameras.main.width;
    var height = this.cameras.main.height;

    var loadingText = this.make.text({
      x: width / 2,
      y: height / 2 - 50,
      text: i18n.t("miniGames.general.loading") + "...",
      style: {
        font: "20px monospace",
        fill: "#000000",
      },
    });
    loadingText.setOrigin(0.5, 0.5);

    var percentText = this.make.text({
      x: width / 2,
      y: height / 2 - 5,
      text: "0%",
      style: {
        font: "18px monospace",
        fill: "#000000",
      },
    });
    percentText.setOrigin(0.5, 0.5);

    var assetText = this.make.text({
      x: width / 2,
      y: height / 2 + 50,
      text: "",
      style: {
        font: "18px monospace",
        fill: "#000000",
      },
    });
    assetText.setOrigin(0.5, 0.5);

    this.load.on("progress", function (value) {
      percentText.setText(parseInt(value * 100) + "%");
      progressBar.clear();
      progressBar.fillStyle(0x000000, 1);
      progressBar.fillRect(110, 280, 260 * value, 30);
    });

    this.load.on("fileprogress", function (file) {
      assetText.setText("Loading asset: " + file.key);
    });
    this.load.on("complete", function () {
      progressBar.destroy();
      progressBox.destroy();
      loadingText.destroy();
      percentText.destroy();
      assetText.destroy();
    });
    // Loading logic END

    //*** load images ***//
    // general Images
    // buttons
    this.load.image(
      "imgCloseBtn",
      "/assets/gameAssets/common/images/btn-close.png"
    );
    this.load.image("imgOkBtn", "/assets/gameAssets/common/images/btn-ok.png");
    this.load.image(
      "imgOptionBtn",
      "/assets/gameAssets/common/images/btn-option.png"
    );
    this.load.image(
      "imgSettingBtn",
      "/assets/gameAssets/common/images/btn-setting.png"
    );

    // panel bg
    this.load.image(
      "imgRoundPanel",
      "/assets/gameAssets/common/images/panel-round.png"
    );
    this.load.image(
      "imgSettingPanel",
      "/assets/gameAssets/common/images/panel-setting.png"
    );
    this.load.image(
      "imgScorePanel",
      "/assets/gameAssets/common/images/panel-score.png"
    );
    this.load.image(
      "imgAnswerPanel",
      "/assets/gameAssets/common/images/panel-answer.png"
    );

    // others
    this.load.image(
      "imgAnswerTag",
      "/assets/gameAssets/common/images/answer-tag.png"
    );
    this.load.image(
      "imgCorrectAns",
      "/assets/gameAssets/common/images/answer-correct.png"
    );
    this.load.image(
      "imgWrongAns",
      "/assets/gameAssets/common/images/answer-wrong.png"
    );
    this.load.image(
      "imgCorrectMark",
      "/assets/gameAssets/common/images/mark-correct.png"
    );
    this.load.image(
      "imgWrongMark",
      "/assets/gameAssets/common/images/mark-wrong.png"
    );
    this.load.image(
      "imgExclMark",
      "/assets/gameAssets/common/images/mark-exclamation.png"
    );
    this.load.image("imgSpeech", "/assets/gameAssets/common/images/speech.png");

    // images specific to the game
    this.load.image(
      "imgBackground",
      "/assets/gameAssets/fishingGame/images/background.png"
    );
    this.load.image(
      "imgBackgroundX",
      "/assets/gameAssets/fishingGame/images/backgroundx.png"
    );
    this.load.image("imgBG", "/assets/gameAssets/fishingGame/images/bg.png");
    this.load.image(
      "imgGameTitle",
      "/assets/gameAssets/fishingGame/images/game-title.png"
    );

    this.load.image(
      "imgWave1",
      "/assets/gameAssets/fishingGame/images/wave-1.png"
    );
    this.load.image(
      "imgWave2",
      "/assets/gameAssets/fishingGame/images/wave-2.png"
    );
    this.load.image(
      "imgWave3",
      "/assets/gameAssets/fishingGame/images/wave-3.png"
    );
    this.load.image(
      "imgWave4",
      "/assets/gameAssets/fishingGame/images/wave-4.png"
    );

    this.load.image(
      "imgFarShip",
      "/assets/gameAssets/fishingGame/images/far-ship.png"
    );
    this.load.image(
      "imgBoat",
      "/assets/gameAssets/fishingGame/images/boat.png"
    );
    this.load.image("imgRod", "/assets/gameAssets/fishingGame/images/rod.png");
    this.load.image(
      "imgRodHook",
      "/assets/gameAssets/fishingGame/images/rod-hook.png"
    );
    this.load.image(
      "imgRodWire",
      "/assets/gameAssets/fishingGame/images/rod-wire.png"
    );
    this.load.image(
      "imgBox",
      "/assets/gameAssets/fishingGame/images/box.png"
    );
    this.load.image(
      "imgClam",
      "/assets/gameAssets/fishingGame/images/clam.png"
    );
    this.load.image(
      "imgBlue",
      "/assets/gameAssets/fishingGame/images/blue.png"
    );
    this.load.image(
      "imgShrimp",
      "/assets/gameAssets/fishingGame/images/shrimp.png"
    );
    this.load.image(
      "imgBossFish",
      this.bossImage
        ? this.bossImage
        : "/assets/gameAssets/fishingGame/images/boss-fish.png"
    );
    this.load.image(
      "imgTrash",
      "/assets/gameAssets/fishingGame/images/trash.png"
    );
    this.load.image(
      "imgFishingBtn",
      "/assets/gameAssets/fishingGame/images/btn.png"
    );
    this.load.image(
      "imgFishingBtnX",
      "/assets/gameAssets/fishingGame/images/btn-x.png"
    );

    this.load.image(
      "imgFriendBtn",
      "/assets/gameAssets/fishingGame/images/friend-btn.png"
    );

    this.load.image(
      "imgFishingBarBack",
      "/assets/gameAssets/fishingGame/images/fishing-bar-back.png"
    );
    this.load.image(
      "imgFishingBarOutline",
      "/assets/gameAssets/fishingGame/images/fishing-bar-outline.png"
    );
    this.load.image(
      "imgFishingBarOutlineBoss",
      "/assets/gameAssets/fishingGame/images/fishing-bar-outline-boss.png"
    );
    this.load.image(
      "imgFishingBarFish",
      "/assets/gameAssets/fishingGame/images/fishing-bar-fish.png"
    );
    this.load.image(
      "imgFishingBarHook",
      "/assets/gameAssets/fishingGame/images/fishing-bar-hook.png"
    );
    this.load.image(
      "imgReactionLove",
      "/assets/gameAssets/fishingGame/images/love.png"
    );
    this.load.image(
      "imgReaction!",
      "/assets/gameAssets/fishingGame/images/!.png"
    );

    this.load.image(
      "imgHamochi",
      "/assets/gameAssets/fishingGame/images/hamochi.png"
    );

    this.load.image("imgHamochiVideo", "/assets/gameAssets/common/tutorial/hamochi-video.png");

    // //*** Load Sprite Sheets ***//

    // Common
    this.load.spritesheet(
      "sptIdea",
      "/assets/gameAssets/common/sprites/idea.png",
      {
        frameWidth: 120,
        frameHeight: 120,
      }
    );

    // Specific
    this.load.spritesheet(
      "sptSplash",
      "/assets/gameAssets/fishingGame/sprites/splash.png",
      {
        frameWidth: 100,
        frameHeight: 100,
      }
    );

    this.load.spritesheet(
      "sptMagic",
      "/assets/gameAssets/fishingGame/sprites/magic.png",
      {
        frameWidth: 150,
        frameHeight: 50,
      }
    );

    // //*** load audio files ***//
    // general sfxs
    this.load.audio(
      "sfxCorrectAnswer",
      "/assets/gameAssets/common/sounds/answer-correct.wav"
    );
    this.load.audio(
      "sfxWrongAnswer",
      "/assets/gameAssets/common/sounds/answer-wrong.wav"
    );
    this.load.audio(
      "sfxScoreIncrease",
      "/assets/gameAssets/common/sounds/score-increase.wav"
    );
    this.load.audio(
      "sfxSimpleClick",
      "/assets/gameAssets/common/sounds/simple-click.wav"
    );
    this.load.audio(
      "sfxWarningAlarm",
      "/assets/gameAssets/common/sounds/warning-alarm.wav"
    );
    this.load.audio(
      "sfxFailure",
      "/assets/gameAssets/common/sounds/game-failure.wav"
    );
    this.load.audio(
      "sfxSuccess",
      "/assets/gameAssets/common/sounds/game-success.wav"
    );
    this.load.audio("sfxClose", "/assets/gameAssets/common/sounds/close.wav");
    this.load.audio("sfxRound", "/assets/gameAssets/common/sounds/round.wav");
    this.load.audio("sfxGo", "/assets/gameAssets/common/sounds/go.wav");

    // sfx specific to the game
    this.load.audio("bgm", "/assets/gameAssets/fishingGame/sounds/bgm.mp3");
    this.load.audio(
      "sfxFishSuccess",
      "/assets/gameAssets/fishingGame/sounds/fish-success.wav"
    );
    this.load.audio(
      "sfxFishFailed",
      "/assets/gameAssets/fishingGame/sounds/fish-failed.wav"
    );
    this.load.audio(
      "sfxSplash",
      "/assets/gameAssets/fishingGame/sounds/splash.wav"
    );
    this.load.audio(
      "sfxStart",
      "/assets/gameAssets/fishingGame/sounds/start.wav"
    );

    this.load.audio(
      "sfxFriend",
      "/assets/gameAssets/fishingGame/sounds/friend.wav"
    );

    this.load.audio(
      "sfxMagic",
      "/assets/gameAssets/fishingGame/sounds/magic.wav"
    );

    this.load.audio(
      "sfxMagicW",
      "/assets/gameAssets/fishingGame/sounds/magic-w.wav"
    );

    //*** Load Avatars ***//
    // load avatar data
    for (var key of Object.keys(this.avatar)) {
      if (this.avatar[key] && key != "color") {
        this.load.image(key, this.avatar[key]);
      }
    }

    // load friend avatar data
    for (var _key of Object.keys(this.friend.avatar)) {
      if (this.friend.avatar[_key] && _key != "color") {
        this.load.image(_key, this.friend.avatar[_key]);
      }
    }

    // load avatar masks
    this.load.image("maskAngry", "/assets/gameAssets/common/masks/M0021.png");
    this.load.image("maskNice", "/assets/gameAssets/common/masks/M0012.png");
    this.load.image("maskFear", "/assets/gameAssets/common/masks/M0040.png");
    this.load.image("maskSad", "/assets/gameAssets/common/masks/M0031.png");
    this.load.image("maskHappy", "/assets/gameAssets/common/masks/M0013.png");
    this.load.image("maskMad", "/assets/gameAssets/common/masks/M0080.png");
    this.load.image("maskStun", "/assets/gameAssets/common/masks/M0060.png");
    this.load.image("maskWeary", "/assets/gameAssets/common/masks/M0030.png");
    this.load.image("maskYummy", "/assets/gameAssets/common/masks/M0050.png");
    this.load.image("maskGrimace", "/assets/gameAssets/common/masks/M0070.png");
    this.load.image("maskScold", "/assets/gameAssets/common/masks/M0080.png");
    this.load.image("maskWearyx", "/assets/gameAssets/common/masks/M0020.png");
    this.load.image("maskHappyx", "/assets/gameAssets/common/masks/M0011.png");
    this.load.image("maskAttack", "/assets/gameAssets/common/masks/M0090.png");

    this.load.image("maskBoss", "/assets/gameAssets/common/masks/M1000.png");
    this.load.image(
      "maskBossHurt",
      "/assets/gameAssets/common/masks/M1002.png"
    );
    this.load.image(
      "maskBossHappy",
      "/assets/gameAssets/common/masks/M1003.png"
    );
    this.load.image(
      "maskBossAttack",
      "/assets/gameAssets/common/masks/M1004.png"
    );
  }

  create() {
    this.registry.destroy();
    this.events.off();
    this.scene.stop("LoadingScene");
    this.scene.start("LandingScene", {
      noOfRounds: this.noOfRounds,
      score: 0,
      round: 1,
      qCount: 0,
      avatar: this.avatar,
      name: this.name,
      questions: this.questions,
      friend: this.friend,
      difficulty: this.difficulty,
      countCorrect: 0,
      bossRound: this.bossRound,
      bossName: this.bossName,
      env: this.env,
    });
  }
}
