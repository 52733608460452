import { Scene } from "phaser";
import store from "@/store";

export default class ApiError extends Scene {
  constructor() {
    super({ key: "ApiError" });
  }

  init(data) {
    this.sceneName = data.sceneName;
    this.mgs = data.mgs;
  }

  preload() {
   
  }

  create() {
    const words = this.mgs.split(" ");
  const lines = [];

  while (words.length > 0) {
    lines.push(words.splice(0, 3).join(" "));
  }
    // Sounds
    this.closeSound = this.sound.add("close");
    this.simpleClickSound = this.sound.add("simpleClick");
    this.sfxError = this.sound.add("sfxError");

    this.add.rectangle(240, 427, 480, 854, 0x000000).setAlpha(0.5);
    this.add.image(240, 310, "settingPanel").setScale(1, 1.05);

  //  this.add.rectangle(240, 370, 250, 150, 0x000000).setAlpha(0.5);
    this.graphics = this.add.graphics();

        this.graphics.fillStyle(0xFF0000, 0.1);

        //  32px radius on the corners
        this.graphics.fillRoundedRect(105, 270, 270, 180, 32);
    this.closeBtn = this.add.image(360, 180, "closeBtnImage").setScale(0.3).on("pointerdown", this.clickClose, this).setInteractive({ useHandCursor: true });
    this.add.image(240, 220, "loadingError").setDisplaySize(300, 300).setScale(0.15);


    const centerX = this.cameras.main.centerX;
     const centerY = this.cameras.main.centerY-70;

  lines.map((line, index) => {
    const text = this.add.text(centerX, centerY + (index - (lines.length - 1) / 2) * 30, line, {
      fontFamily: "Sassoon, sans-serif",
      fontSize: 20,
      fill: '#FF0000'
    });

    // Center the text
    text.setOrigin(0.5, 0.5);

    return text;
  });
    // this.resetText = this.add
    // .text(0, 330, "You received!", {
    //   fontFamily: "Sassoon, sans-serif",
    //   fontSize: 30,
    //   color: "#000000",
    // })
    // .setOrigin(0, 0.5)
    // .setFontStyle("bold")
    // .setDepth(2);

    //  let textWidth = this.resetText.width;
    // this.resetText.setX(240 - textWidth / 2);

    this.sfxError.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxError.play();

  }

  clickClose() {
    this.closeSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.closeSound.play();
    this.scene.stop("ApiError");
    this.scene.resume(this.sceneName);
  }

  update() {
    //
  }
}
