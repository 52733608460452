import { Scene } from "phaser";
import store from "@/store";
import i18n from "@/plugins/i18n";
import axios from "axios";

export default class CompletionGift extends Scene {
  constructor() {
    super({ key: "CompletionGift" });
  }

  init(data) {
    this.sceneName = data.sceneName;
    this.avatar = data.avatar;
    this.ladder = data.ladder;
    this.blueStar = data.blueStar;
    this.game = data.game;
    this.end = data.end;
    this.wizard = data.wizard;
    this.questions = data.questions;
    this.timestampReset = data.timestampReset;
    this.colors = data.colors,
    this.env = data.env;
    this.schoolYear = data.schoolYear,
    this.classroomMonth = data.classroomMonth,
    this.isHasSpecialItem = data.isHasSpecialItem
  }

  preload() {
   
  }

  create() {

    // Sounds
    this.simpleClickSound = this.sound.add("simpleClick");
    this.sfxSurprise = this.sound.add("sfxSurprise");
    this.sfxPopUp = this.sound.add("sfxPopUp");

    this.add.rectangle(240, 427, 480, 854, 0x000000).setAlpha(0.5);
   
  this.giftImage =  this.add.image(240, 400, "completionGift1").setScale(0.7).on("pointerdown", this.openGift, this).setInteractive({ useHandCursor: true });
  this.gift = Math.floor(Math.random() * (this.game.currentFloor - 1 + 1)) + 1

  this.sfxPopUp.setVolume(
    store.state.settings.data.audio.sfx *
      store.state.settings.data.audio.master
  );
  this.sfxPopUp.play();
  this.clickText = this.add
      .text(0, 580, `${i18n.t("action.clickToClaim")}`, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 30,
        color: "#ffffff",
        stroke: "#000000",
          strokeThickness: 2,
      })
      .setOrigin(0, 0.5)
      .setFontStyle("bold")
      .setDepth(2);
      let textWidth = this.clickText.width;
      this.clickText.setX(240 - textWidth / 2);
    this.avatarBounceTweens = this.tweens.add({
      targets: this.giftImage,
      scaleY: 0.6,
   //   y: "-=10",
      scaleX: 0.6,
      duration: 2000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });
   

  }

  openGift(){
    this.giftImage.setVisible(false)
    this.giftImage2 =  this.add.image(240, 400, "completionGift2").setScale(0.7);
    this.sfxSurprise.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxSurprise.play();   
    let token = store.state.auth.data.Session.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(
        `${this.env.VUE_APP_URL_API_GAME}/api/v1/en/game/item/updateMany`,
        { data: '[{"action":"add","itemKey":"goldcoin","quantity":' + this.gift + "}]", },
        { headers }
      )
      .then((response) => {
        // Process the data returned from the API
        console.log(response.data);
      
    this.time.delayedCall(1000, () => {
      this.scene.pause();
      this.scene.start("CollectItem", {
        type:"completionGift",
        sceneName: this.sceneName,
        itemCount: "+"+this.gift,
        itemKey: "goldcoin",
        avatar: this.avatar,
        ladder: this.ladder,
        blueStar: this.blueStar,
        game: this.game,
        end: this.end,
        wizard: this.wizard,
        questions: this.questions,
        colors: this.colors,
        env: this.env,
        timestampReset: this.timestampReset,
        schoolYear: this.schoolYear,
        classroomMonth: this.classroomMonth,
        isHasSpecialItem:this.isHasSpecialItem,
      });
      })
      })
      .catch((error) => {
        // Handle any errors that occur during the API call
        console.error("Error:", error);
      });
  }

  update() {
    //
  }
}
