import { Scene } from "phaser";
// import store from "@/store";
import router from "@/router";
// import i18n from "@/plugins/i18n";
// import _ from "lodash";
import Phaser from "phaser";

export default class MachineScene extends Scene {
  constructor() {
    super({ key: "MachineScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.name = data.name;
    this.round = data.round;
    this.qCount = data.qCount;
    this.questions = data.questions;
    this.score = data.score ? data.score : 0;
    this.friend = data.friend;
    this.forceFriend = data.forceFriend;
    this.countCorrect = data.countCorrect;
    this.difficulty = data.difficulty;
    this.noOfRounds = data.noOfRounds;
    this.noOfCuts = data.noOfCuts;
    this.bossRound = data.bossRound;
    this.bossImage = data.bossImage;
    this.currentCampTileX = data.currentCampTileX;
    this.nextCampTileX = data.nextCampTileX;
    this.totalBones = data.totalBones;
    this.collectedBones = data.collectedBones;
    this.collectedSkull = data.collectedSkull;
    this.lampTime = data.lampTime;
  }

  playerAvatarObject = [];

  create() {
    this.screen1 = this.add
      .rectangle(240, 427, 480, 854, 0x000000)
      .setDepth(99);
    this.screen2 = this.add
      .rectangle(240, 427, 480, 854, 0xffffff)
      .setDepth(99)
      .setAlpha(0);

    this.imgBackground = this.add
      .image(0, 0, "imgBackground")
      .setDisplaySize(480, 854)
      .setOrigin(0);

    this.machine = this.add
      .sprite(350, 520, "sptMachine")
      .setFrame(0)
      .setDepth(3);

    this.anims.create({
      key: "machineAnim",
      frames: this.anims.generateFrameNumbers("sptMachine", {
        frames: [
          1, 2, 3, 2, 2, 3, 2, 3, 2, 3, 3, 2, 2, 3, 2, 2, 1, 1, 2, 3, 2, 2, 3,
          2, 3, 2, 3, 3, 2, 2, 3, 2, 2, 1, 0,
        ],
      }),
      frameRate: 10,
    });

    //* Player Avatar *//
    for (let key of Object.keys(this.avatar)) {
      if (this.avatar[key]) {
        if (key == "body") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(150, 620, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(150, 620, key)
              .setTint("0x" + this.avatar.color)
              .setScale(0.25)
              .setAlpha(0.3)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        } else if (key != "color" && key != "right") {
          this.playerAvatarObject.push(
            this.physics.add
              .sprite(150, 620, key)
              .setScale(0.25)
              .setCollideWorldBounds(true)
              .setDepth(6)
          );
        }
      }
    }

    this.playerTween = this.tweens.add({
      targets: this.playerAvatarObject,
      scaleY: 0.26,
      scaleX: 0.24,
      y: "-=10",
      duration: 1000,
      ease: "Sine.easeInOut",
      repeat: -1,
      yoyo: true,
    });

    this.tweens.add({
      targets: this.screen1,
      alpha: 0,
      duration: 1000,
      ease: "Sine.easeInOut",
      onComplete: () => {
        this.tempBones = ["imgBone1", "imgBone2", "imgBone3"];
        if(this.collectedBones < this.totalBones){
          this.time.delayedCall(1000, () => {
            this.npcSpeech = this.add
              .image(350, 450, "imgSpeech")
              .setOrigin(0.5, 1)
              .setDepth(3)
              .setScale(0.5);

            this.add
              .image(350, 420, "imgBone4")
              .setScale(0.3)
              .setOrigin(0.5, 1)
              .setDepth(3);

            if (this.playerMask) {
              this.playerMask.destroy();
            }
            this.playerMask = this.add
              .image(
                this.playerAvatarObject[0].x,
                this.playerAvatarObject[0].y,
                "maskSad"
              )
              .setScale(0.25)
              .setDepth(6);
          });

          this.tweens.add({
            targets: this.screen1,
            alpha: 1,
            delay: 4000,
            duration: 1000,
            ease: "Sine.easeInOut",
            onComplete: () => {
              router.go();
            },
          });
        }else{
          for (let i = 0; i < this.collectedBones; i++) {
            if (i == this.collectedBones - 1) {
              if (this.collectedSkull > 0) {
                let bone = this.add
                  .image(120, 600, "imgBone4")
                  .setScale(0.3)
                  .setOrigin(0);
                this.time.delayedCall(200 * i, () => {
                  this.throw(bone, 320, 500);
                });
  
                this.time.delayedCall(i * 200 + 1500, () => {
                  this.machine.play("machineAnim");
                  for (let j = 0; j < 9; j++) {
                    let puz = this.add.image(320, 500, "imgPuz").setOrigin(0);
                    this.time.delayedCall(200 * j + 3500, () => {
                      if (this.playerMask) {
                        this.playerMask.destroy();
                      }
                      this.playerMask = this.add
                        .image(
                          this.playerAvatarObject[0].x,
                          this.playerAvatarObject[0].y,
                          "maskWeary"
                        )
                        .setScale(0.25)
                        .setDepth(6);
                      this.throw(puz, 600, 400);
                      this.tweens.add({
                        targets: this.screen2,
                        alpha: 1,
                        duration: 500,
                        delay: 1000,
                        ease: "Sine.easeInOut",
                        onComplete: () => {
                          this.time.delayedCall(500, () => {
                            this.scene.start("PuzzleScene", {
                              avatar: this.avatar,
                              name: this.name,
                              round: this.round,
                              score: this.score,
                              questions: this.questions,
                              friend: this.friend,
                              difficulty: this.difficulty,
                              countCorrect: this.countCorrect,
                              noOfRounds: this.noOfRounds,
                              noOfCuts: this.noOfCuts,
                              bossRound: this.bossRound,
                              bossImage: this.bossImage,
                              currentCampTileX: this.nextCampTileX,
                              nextCampTileX: this.nextNextCampTileX,
                              totalBones: this.totalBones,
                              collectedBones: this.collectedBones,
                              collectedSkull: this.collectedSkull,
                              lampTime: this.lampTime,
                            });
                          });
                        },
                      });
                    });
                  }
                });
              } else {
                let bone = this.add
                  .image(
                    120,
                    600,
                    this.tempBones[
                      Math.floor(Math.random() * this.tempBones.length)
                    ]
                  )
                  .setScale(0.3)
                  .setOrigin(0);
                this.time.delayedCall(200 * i, () => {
                  this.throw(bone, 320, 500);
                });
  
                this.time.delayedCall(i * 200 + 1500, () => {
                  this.npcSpeech = this.add
                    .image(350, 450, "imgSpeech")
                    .setOrigin(0.5, 1)
                    .setDepth(3)
                    .setScale(0.5);
  
                  this.add
                    .image(350, 420, "imgBone4")
                    .setScale(0.3)
                    .setOrigin(0.5, 1)
                    .setDepth(3);
  
                  if (this.playerMask) {
                    this.playerMask.destroy();
                  }
                  this.playerMask = this.add
                    .image(
                      this.playerAvatarObject[0].x,
                      this.playerAvatarObject[0].y,
                      "maskSad"
                    )
                    .setScale(0.25)
                    .setDepth(6);
                });
  
                this.tweens.add({
                  targets: this.screen1,
                  alpha: 1,
                  delay: 3000,
                  duration: 1000,
                  ease: "Sine.easeInOut",
                  onComplete: () => {
                    router.go();
                  },
                });
              }
            } else {
              let bone = this.add
                .image(
                  120,
                  600,
                  this.tempBones[
                    Math.floor(Math.random() * this.tempBones.length)
                  ]
                )
                .setScale(0.3)
                .setOrigin(0);
              this.time.delayedCall(200 * i, () => {
                this.throw(bone, 320, 500);
              });
            }
          }
        }
      },
    });
  }

  throw(bone, ex, ey) {
    const startX = bone.x;
    const startY = bone.y;
    const endX = ex;
    const endY = ey;

    const duration = 1000;
    const height = 500;

    this.tweens.add({
      targets: [bone],
      x: endX,
      y: endY,
      duration: duration,
      ease: "Linear",
      onUpdate: (tween, target) => {
        const progress = tween.progress;
        const curveX = Phaser.Math.Interpolation.Bezier(
          [startX, (startX + endX) / 2, endX],
          progress
        );
        const curveY = Phaser.Math.Interpolation.Bezier(
          [startY, startY - height, endY],
          progress
        );
        target.x = curveX;
        target.y = curveY;
      },
      onComplete: () => {},
    });
  }

  update() {
    if (this.playerMask && this.playerAvatarObject[0]) {
      this.playerMask.x = this.playerAvatarObject[0].x;
      this.playerMask.y = this.playerAvatarObject[0].y;
    }
  }
}
