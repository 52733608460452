import { Scene } from "phaser";
// import i18n from "@/plugins/i18n";

export default class PlayScene extends Scene {
  constructor() {
    super({ key: "PlayScene" });
  }
  init(data) {
    this.avatar = data.avatar;
    this.ladder = data.ladder;
    this.blueStar = data.blueStar;
    this.game = data.game;
    this.end = data.end;
    this.wizard = data.wizard;
    this.questions = data.questions;
    this.colors =data.colors,
    this.env = data.env;
  }
  avatarObject = [];
  avatarX = 240;
  preload() {}

  create() {
    const centerX = this.avatarX;
    const centerY = 420;
    this._backgroundImage = this.add
    .image(0, 0, "imgBackground")
    .setDisplaySize(480, 854)
    .setOrigin(0);
    // Create a black background
    this.add.rectangle(0, 0, 480, 854, 0x641E16).setOrigin(0);
    // Create a white circle at the center
    const circle = this.add.circle(240, 427, 500, 0xffffff);

    // Set origin to the center for proper scaling
    circle.setOrigin(0.5);
 //* Player Avatar *//
 for (let key of Object.keys(this.avatar)) {
  if (this.avatar[key]) {
    if (key == "body") {
      this.avatarObject.push(
        this.physics.add
          .sprite(centerX, centerY, key)
          .setScale(0.25)
          .setCollideWorldBounds(true)
          .setDepth(1)
      );
      this.avatarObject.push(
        this.physics.add
          .sprite(centerX, centerY, key)
          .setTint("0x" + this.avatar.color)
          .setScale(0.25)
          .setAlpha(0.3)
          .setCollideWorldBounds(true)
          .setDepth(1)
      );
    } else if (key != "color") {
      this.avatarObject.push(
        this.physics.add
          .sprite(centerX, centerY, key)
          .setScale(0.25)
          .setCollideWorldBounds(true)
          .setDepth(1)
      );
    }
  }
}
this.tweens.add({
  targets: this.avatarObject,
  alpha: 0, // Set alpha to 0 (completely transparent)
  duration: 700, // Duration of the tween in milliseconds
  onComplete: () => {
    this.avatarObject.visible = false; // Once completely transparent, hide the object
  }
});
    // Create a tween to reduce the circle's size to 0
    this.tweens.add({
      targets: circle,
      scaleX: 0,
      scaleY: 0,
      alpha: 0,
      duration: 700, // Duration of the tween in milliseconds
      onComplete: () => {
        circle.destroy(); 
        this.cameras.main.fadeOut(1000, 0, 0, 0)
    this._logo = this.add.image(240, 327, "logo").setScale(0.8);
        this._continueBtn = this.add
          .image(240, 550, "optionButton")
          .setScale(0.35).setInteractive({ useHandCursor: true })
          .on("pointerdown", this.play, this);
        this._continueTxt = this.add.text(220, 540, "PLAY", {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 20,
          color: "#000",
          align: "center",
        })
      },
    });
  }
  play(){
    this.time.delayedCall(700, () => {
     
    this.game.currentTile = this.game.currentTile == 5? 1: this.game.currentTile +1 
    this.game.currentFloor = this.game.currentTile == 1?  this.game.currentFloor+1:  this.game.currentFloor
    this.scene.start("LandingScene", {
      avatar: this.avatar,
      ladder: this.ladder,
      blueStar: this.blueStar,
      game: this.game,
      end: this.end,
      wizard: this.wizard,
      questions: this.questions,
      colors: this.colors,
      env: this.env,
    });
     
  })
  }

  update() {
   
  }
}
