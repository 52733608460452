import { Scene } from "phaser";
import store from "@/store";
import axios from "axios";
// import moment from "moment";
import i18n from "@/plugins/i18n";
import _ from "lodash";

export default class CollectWearableItem extends Scene {
  constructor() {
    super({ key: "CollectWearableItem" });
  }

  init(data) {
    (this.type = data.type), (this.sceneName = data.sceneName);
    this.itemKey = data.itemKey;
    this.itemCount = data.itemCount;
    this.avatar = data.avatar;
    this.ladder = data.ladder;
    this.blueStar = data.blueStar;
    this.game = data.game;
    this.end = data.end;
    this.wizard = data.wizard;
    this.questions = data.questions;
    this.timestampReset = data.timestampReset;
    (this.colors = data.colors), (this.env = data.env);
    this.schoolYear = data.schoolYear,
    this.classroomMonth = data.classroomMonth,
    this.isHasSpecialItem = data.isHasSpecialItem
  }

  preload() {}

  create() {
    // Sounds
    this.closeSound = this.sound.add("close");
    this.simpleClickSound = this.sound.add("simpleClick");
    this.sfxCollectItem = this.sound.add("sfxCollectItem");
    this.sfxPopUp = this.sound.add("sfxPopUp");

    this.add.rectangle(240, 427, 480, 854, 0x000000).setAlpha(0.5);
    this.add
      .image(240, 430, "imgCollectPanel")
      .setDisplaySize(600, 400)
      .setScale(1.1);
    this.add
      .image(240, 210, this.itemCount === "+0" ? "mochiFreeze" : "mochiRainbow")
      .setDisplaySize(300, 300)
      .setScale(0.21);
    //this.add.image(240, 230, "mochiRainbow").setDisplaySize(300, 300).setScale(0.21);

    this.sfxPopUp.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.sfxPopUp.play();
    this.addItem();
    this.spendFoot()
    this.okBtn = this.add
      .image(240, 550, "okBtnImage")
      .setScale(0.8,0.63)
      .setInteractive({ cursor: "pointer" })
      .on(
        "pointerdown",
        this.itemKey == "footprint" ? this.collectFoot : this.goHome,
        this
      );
    this.okBtnTxt = this.add
      .text(238, 550, i18n.t("action.ok"), {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 35,
        color: "#000",
      })
      .setOrigin(0.5)
      .setScale(1);

      this.wearBtn = this.add
      .image(240, 610, "okBtnImage")
      .setScale(0.8,0.63)
      .setTint("0x00FF00")
      .setInteractive({ cursor: "pointer" })
      .on(
        "pointerdown",
         this.wearItem,
        this
      );
    this.wearBtnTxt = this.add
      .text(238, 610, "WEAR NOW", {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 20,
        color: "#000",
      })
      .setOrigin(0.5)
      .setScale(1);
  }
  addItem() {
    this.resetText = this.add
      .text(0, 310, i18n.t("view.PageBuildingMission.rewardReceivedMessage"), {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 30,
        color: "#000000",
      })
      .setOrigin(0, 0.5)
      .setFontStyle("bold")
      .setDepth(2);
    let textWidth = this.resetText.width;
    this.resetText.setX(240 - textWidth / 2);
    this.add.image(240, 420, "itemFrame").setScale(0.6);
    this.add
      .image(240, 395, "" + this.itemKey)
      .setDisplaySize(300, 300)
      .setScale(1);
    this.itemText = this.add
      .text(0, 476, "" + this.itemCount, {
        fontFamily: "Sassoon, sans-serif",
        fontSize: 25,
        color: "#000000",
      })
      .setOrigin(0, 0.5)
      .setFontStyle("bold")
      .setDepth(2);
    let textCountWidth = this.itemText.width;
    this.itemText.setX(240 - textCountWidth / 2);
  }
  async goHome() {
   
    this.simpleClickSound.setVolume(
      store.state.settings.data.audio.sfx *
        store.state.settings.data.audio.master
    );
    this.simpleClickSound.play();
      this.sfxCollectItem.setVolume(
        store.state.settings.data.audio.sfx *
          store.state.settings.data.audio.master
      );
      this.sfxCollectItem.play();

    this.scene.stop("CollectWearableItem");
    this.game.currentTile =
            this.game.currentTile == 5 ? 1 : this.game.currentTile + 1;
          this.game.currentFloor =
            this.game.currentTile == 1
              ? this.game.currentFloor + 1
              : this.game.currentFloor; 
    this.scene.start(this.sceneName, {
      avatar: this.avatar,
      ladder: this.ladder,
      blueStar: this.blueStar,
      game: this.game,
      end: this.end,
      wizard: this.wizard,
      questions: this.questions,
      colors: this.colors,
      env: this.env,
      timestampReset: this.timestampReset,
      schoolYear: this.schoolYear,
      classroomMonth: this.classroomMonth,
      isHasSpecialItem:this.isHasSpecialItem,
    });
  }
  wearItem(){
   // this.scene.stop("CollectWearableItem");
    // this.scene.resume(this.sceneName);
    let oldAvatar = _.cloneDeep(JSON.parse(store.state.auth.data.Player.avatar));
    oldAvatar.hat = 'S1002_H'

this.avatar.hat = null
this.avatar.santaHat = 'S1002_H'
let token = store.state.auth.data.Session.token;
const headers = {
  Authorization: `Bearer ${token}`,
};
axios
  .post(
    `${this.env.VUE_APP_URL_API_GAME}/api/v1/en/game/avatar/save`,
    {
      avatar: JSON.stringify(oldAvatar),
    },
    { headers }
  )
  .then((response) => {
    var authData = _.cloneDeep(store.state.auth.data)
    authData.Player = response.data.Player;
    store.commit("updateAuth", authData);
    this.game.currentTile =
            this.game.currentTile == 5 ? 1 : this.game.currentTile + 1;
          this.game.currentFloor =
            this.game.currentTile == 1
              ? this.game.currentFloor + 1
              : this.game.currentFloor;
    this.time.delayedCall(700, () => {
            this.scene.start("WearItem", {
              type: "specialGift",
              sceneName: "SpecialLandingScene",
              itemCount: "+1",
              itemKey: "S1002_H",
              avatar: this.avatar,
              ladder: this.ladder,
              blueStar: this.blueStar,
              game: this.game,
              end: this.end,
              wizard: this.wizard,
              questions: this.questions,
              colors: this.colors,
              env: this.env,
              timestampReset: this.timestampReset,
              schoolYear: this.schoolYear,
              classroomMonth: this.classroomMonth,
              isHasSpecialItem:this.isHasSpecialItem,
            });
          });
  })
  .catch((error) => {
    // Handle any errors that occur during the API call
    console.error("Error:", error);
  });
  
  }
  spendFoot() {
 
    let token = store.state.auth.data.Session.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(
        `${this.env.VUE_APP_URL_API_GAME}/api/v1/en/game/footprint/spend`,
        { quantity: 1 },
        { headers }
      )
      .then(async () => {
      await  this.moveToNext();
      })
      .catch((error) => {
        // Handle any errors that occur during the API call
        console.error("Error:", error);
      });
  }
  moveToNext() {
    let token = store.state.auth.data.Session.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(
        `${this.env.VUE_APP_URL_API_GAME}/api/v2/en/game/dailyGame/move`,
        { schoolYear: this.schoolYear , classroomMonth: this.classroomMonth},
        { headers }
      )
      .then(() => {
        // Process the data returned from the API
        if(store.state.auth.data.Player.userType != "adult"){
          this.submitLog()
        }
      })
      .catch((error) => {
        // Handle any errors that occur during the API call
        console.error("Error:", error);
      });
  }
  submitLog() {
    let token = store.state.auth.data.Session.token;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(
        `${this.env.VUE_APP_URL_API_LOG}/api/v1/en/dailyGameProgress/add`,
        {
          institutionId: store.state.auth.data.Institution?store.state.auth.data.Institution.id : null,
          classroomId: store.state.auth.data.Classroom?store.state.auth.data.Classroom.id : null,
          studentId: store.state.auth.data.Classroom? store.state.auth.data.User.id : null,
          userId: store.state.auth.data.Classroom?null:store.state.auth.data.User.id,
          schoolYear: this.schoolYear,
          classroomMonth: this.classroomMonth,
          mochiId: store.state.auth.data.Player.mochiId,
          currentFloor: this.game.currentFloor,
          currentTile: this.game.currentTile,
        },
        { headers }
      )
      .catch((error) => {
        // Handle any errors that occur during the API call
        console.error("Error:", error);
      });
  }
  clickClose() {
    this.scene.stop("CollectWearableItem");
    this.scene.start(this.sceneName, {
      avatar: this.avatar,
      ladder: this.ladder,
      blueStar: this.blueStar,
      game: this.game,
      end: this.end,
      wizard: this.wizard,
      questions: this.questions,
      colors: this.colors,
      env: this.env,
      timestampReset:this.timestampReset,
      schoolYear: this.schoolYear,
      classroomMonth: this.classroomMonth,
      isHasSpecialItem:this.isHasSpecialItem,
    });
  }
  update() {
    //
  }
}
