import Phaser from "phaser";
import LoadingScene from "@/games/Discover/scenes/LoadingScene";
import QuestionScene from "@/games/Discover/scenes/QuestionScene";
import IntroScene from "@/games/Discover/scenes/IntroScene";
import PlayScene from "@/games/Discover/scenes/PlayScene";
import BossScene from "@/games/Discover/scenes/BossScene";
import LandingScene from "@/games/Discover/scenes/LandingScene";
import Settings from "@/games/Discover/scenes/Settings";
import PuzzleScene from "@/games/Discover/scenes/PuzzleScene";
import MachineScene from "@/games/Discover/scenes/MachineScene";

import DropShadowPipelinePlugin from "phaser3-rex-plugins/plugins/dropshadowpipeline-plugin.js";

function launch(containerId) {
  return new Phaser.Game({
    type: Phaser.AUTO,
    backgroundColor: 0xf5f5f5,
    parent: containerId,
    scale: {
      parent: containerId,
      mode: Phaser.Scale.FIT,
      autoCenter: Phaser.Scale.CENTER_BOTH,
      width: 480,
      height: 854,
    },
    physics: {
      default: "arcade",
    },
    plugins: {
      global: [
        {
          key: "rexDropShadowPipeline",
          plugin: DropShadowPipelinePlugin,
          start: true,
        },
      ],
    },
    dom: {
      createContainer: true,
    },
    scene: [
      LoadingScene,
      QuestionScene,
      IntroScene,
      PlayScene,
      BossScene,
      Settings,
      LandingScene,
      PuzzleScene,
      MachineScene,
    ],
  });
}

export default launch;
export { launch };
